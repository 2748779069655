import styled from '@emotion/styled';

const PlusContainer = styled.div`
  z-index: 1000;
  background-color: darkslategray;
  aspect-ratio: 1/1;
  color: var(--color-white);
  border: 1px solid var(--color-white);
  border-radius: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 5%;
`;
export default PlusContainer;
