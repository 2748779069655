type PropType = {
  line1: string;
  line2?: string;
};

export default function TitleText({ line1, line2 = '' }: PropType) {
  return (
    <svg viewBox="0 0 300 45">
      <text
        fill="#000"
        fontWeight="700"
        fontFamily="Condensed Roboto, sans-serif"
      >
        <tspan x="0" y="16" fontSize="15px">
          {line1}
        </tspan>
        <tspan x="0" y="30" fontWeight="bold" fontSize="11px">
          {line2}
        </tspan>
      </text>
    </svg>
  );
}
