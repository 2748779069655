import { Buffer } from 'buffer';
import { DateTime } from 'luxon';

import { SanityUUID } from '../../data/sanity/types';
import { AffiliateService } from '../../services/AffiliateService/AffiliateService';
import { isSSR } from '../../utils/helpers';
import { guessMarketByUrl, SemboMarket } from '../../utils/markets';
import { isTypeOf } from '../../utils/typeHelpers';
import { customEncode } from '../smart-search/helpers';
import {
  IngredientQl,
  InputIngredientTypeQl,
  InputMaybe,
  InputPaxGroupQl,
  RecipeQl,
} from '../smart-search/types';
import { addGAParameter, isHotelIngredient } from './helpers';
import { LocalizedRecipe } from './types';

export const getDefaultPax = (): RecipeQl['pax'] => [{ numberOfAdults: 2 }];

export const overridePaxWithDefaultPax = (recipe: RecipeQl): RecipeQl =>
  recipe?.pax ? { ...recipe, pax: getDefaultPax() } : recipe;

export const mapToRecipeWithAffiliateId = (recipe: RecipeQl): RecipeQl => {
  const affiliateId = AffiliateService.getAffiliateId();

  return affiliateId
    ? {
        ...recipe,
        affiliateId,
      }
    : recipe;
};

export const getRecipeFromHotelCode = (
  hotelCode: string,
  host: string
): RecipeQl =>
  mapToRecipeWithAffiliateId({
    market: host,
    ingredients: [
      {
        ingredientType: InputIngredientTypeQl.Hotel,
        hotelCodes: [hotelCode],
      },
    ],
    pax: getDefaultPax(),
  });

export const transformIngredientFromPolygonIdToDestination = async (
  host: string,
  ingredient: InputMaybe<IngredientQl>
) => {
  if (!ingredient || !isHotelIngredient(ingredient)) {
    return ingredient;
  }

  const newIngredient = { ...ingredient };

  let polygonId;
  if (newIngredient?.polygonId) {
    polygonId = newIngredient.polygonId;
    delete newIngredient.polygonId;
  }

  if (newIngredient?.destination?.includes('polygon-id')) {
    polygonId = parseInt(newIngredient?.destination.replace('polygon-id:', ''));
    delete newIngredient.destination;
  }

  if (polygonId) {
    const destination = await (
      await fetch(
        `https://${host.replace(
          'https://',
          ''
        )}/d/accommodation/categorizedautocompletesuggestions?term=polygon-id:${polygonId}`
      )
    ).json();

    if (destination[0].label.startsWith('polygon-id:')) {
      // the label has not been translated, keep using polygon id
      newIngredient.polygonId = polygonId;
    } else {
      // the label has been translated, use destination text
      newIngredient.destination = destination[0].label;
    }
    return newIngredient;
  }
  return newIngredient;
};

export const getRecipeFromDestination = (
  destination: string,
  host: string
): RecipeQl =>
  ({
    market: host,
    ingredients: [
      {
        ingredientType: InputIngredientTypeQl.Hotel,
        destination: destination,
      },
    ],
    pax: getDefaultPax(),
  }) as RecipeQl;

export const getRecipeFromPolygonId = (
  market: SemboMarket,
  polygonId: number
): RecipeQl =>
  ({
    market: `https://${market.host}`,
    ingredients: [
      {
        ingredientType: InputIngredientTypeQl.Hotel,
        polygonId,
      },
    ],
    pax: getDefaultPax(),
  }) as RecipeQl;

export const parseRecipe = (json: string | RecipeQl): RecipeQl =>
  typeof json === 'object' ? json : (JSON.parse(json) as RecipeQl);

export const getUrl = (
  recipe: RecipeQl | string,
  landing?: 'room' | 'destination',
  optionalTripId?: SanityUUID,
  modal?: 'pax',
  market?: SemboMarket
): string => {
  const parsedRecipe = parseRecipe(recipe);

  let evaluatedMarketDomain = parsedRecipe.market;

  if (!evaluatedMarketDomain && market) {
    evaluatedMarketDomain = `https://${market.host}`;
  }

  if (
    !evaluatedMarketDomain &&
    !isSSR() &&
    guessMarketByUrl(window.location.href)
  ) {
    evaluatedMarketDomain = `https://${guessMarketByUrl(window.location.href)?.host}`;
  }

  if (!evaluatedMarketDomain) {
    throw new Error('Could not evaluate market for recipe');
  }

  const baseUrl = `${evaluatedMarketDomain}/travel/plan/`;
  const queryParams = new URLSearchParams({
    recipe: encodeRecipe(parsedRecipe),
  });

  if (parsedRecipe?.startDate) {
    // invalid Date type from SmartSearch types
    const month = DateTime.fromISO(
      parsedRecipe.startDate as unknown as string
    ).month;
    queryParams.set('m', month.toString());
  }

  if (optionalTripId) {
    queryParams.set('published-trip-id', optionalTripId);
  }

  if (landing && landing !== 'destination') {
    queryParams.set('landing', landing);
  }

  if (modal) {
    queryParams.set('modal', modal);
  }

  const path = landing === 'destination' ? 'hotel-selection/0' : '';
  const url = `${baseUrl}${path}?${queryParams.toString()}`;
  return addGAParameter(url);
};

export const encodeRecipe = (recipe: RecipeQl | string): string =>
  encodeRecipeJSON(
    isTypeOf<RecipeQl>(recipe) ? JSON.stringify(recipe) : (recipe as string)
  );

export const mutateRecipeJsonWithSessionStoragePersistedPax = (
  recipeJSON: string
): string => {
  let recipeString = recipeJSON;
  try {
    const paxFromSessionStorage = sessionStorage.getItem('pax');
    if (paxFromSessionStorage) {
      const parsedRecipe: RecipeQl = parseRecipe(parseRecipe(recipeJSON));
      const parsedPax: InputMaybe<InputPaxGroupQl>[] = JSON.parse(
        paxFromSessionStorage
      );
      parsedRecipe.pax = parsedPax;
      recipeString = JSON.stringify(parsedRecipe);
    }
  } catch (error) {
    // session storage might be protected
  }

  return recipeString;
};

export const encodeRecipeJSON = (recipeJSON: string): string => {
  if (isSSR()) {
    return Buffer.from(customEncode(recipeJSON), 'utf-8').toString('base64');
  }

  // Note that we modify the recipe a bit here clientside
  // you could argue that it shouldn't be done in an encoding function
  // but it was the fastest way to accomplish it
  return window.btoa(
    customEncode(mutateRecipeJsonWithSessionStoragePersistedPax(recipeJSON))
  );
};

export const getRecipeWithLocalizedDestinationIngredients = async (
  recipe: RecipeQl
): Promise<LocalizedRecipe> => {
  const transformedIngredients = await Promise.all(
    recipe.ingredients?.map(async (ingredient) =>
      transformIngredientFromPolygonIdToDestination(recipe.market, ingredient)
    ) ?? []
  );

  return {
    ...recipe,
    ingredients: transformedIngredients,
  };
};
