import styled from '@emotion/styled';

const SubPriceField = styled.p`
  font: var(--font-label-l-bold);
  color: var(--color-secondary-40);
  margin: 0;

  /* To align with the price on the right side of this component */
  line-height: var(--line-height-20);
`;
export default SubPriceField;
