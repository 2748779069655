import styled from '@emotion/styled';

import { StarIcon } from '../../icons/StarIcon';
import { breakLine } from '../../utils/stringHelpers';
import PreviewCardText from './PreviewCardText';

type ProductData = Partial<{
  code: string;
  name: string;
  imageURL: string | null;
  rating: number;
}>;

const ComponentContainer = styled.div`
  width: 100%;
  max-width: 350px;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 2fr 1fr;
  background-color: #212427;
  border-radius: 7%;
  grid-column: span 2;
`;

const ProductImage = styled.img`
  border-top-left-radius: 7%;
  border-top-right-radius: 7%;
  object-fit: cover;
  width: 100%;
  aspect-ratio: 16/9;
`;

const InfoContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledPreviewCardText = styled.div`
  height: 50%;
  margin: 0;
  padding: 0;
  padding-top: 1%;
  padding-left: 1%;
`;

const StarIconGroup = styled.div`
  display: flex;
  width: 30%;
  margin-left: 3%;
  margin-bottom: 5%;
`;

export default function ProductCard({ name, imageURL, rating }: ProductData) {
  const { line1, line2 } = breakLine(name ?? '', 28);

  return (
    <ComponentContainer key={name}>
      <ProductImage src={imageURL ?? ''} title={name} alt={name} />
      <InfoContainer>
        <StyledPreviewCardText>
          <PreviewCardText line1={line1} line2={line2} />
        </StyledPreviewCardText>
        <StarIconGroup>
          {Array.from({ length: 5 }, (_, i) => (
            <StarIcon
              key={`star-name-${i}`}
              visible={rating !== undefined && rating > i}
            />
          ))}
        </StarIconGroup>
      </InfoContainer>
    </ComponentContainer>
  );
}
