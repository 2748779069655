import createCache from '@emotion/cache';
import { CacheProvider, EmotionCache } from '@emotion/react';
import camelCase from 'camelcase';
import ReactDOM from 'react-dom/client';

export class WebComponent extends HTMLElement {
  reactComponent: React.ElementType;
  name: string;
  styleSlot: HTMLElement;
  cachePoint: EmotionCache | undefined;
  observedAttributes?: string[];
  componentRoot?: ReactDOM.Root;

  constructor(
    name: string,
    reactComponent: React.ElementType,
    observedAttributes?: string[],
    styleSlot?: HTMLElement
  ) {
    super();
    this.name = name;
    this.reactComponent = reactComponent;
    this.styleSlot = styleSlot ?? document.createElement('section');
    // is this even used?
    this.observedAttributes = observedAttributes;
  }

  connectedCallback() {
    const shadow = this.attachShadow({ mode: 'open' });
    shadow.appendChild(this.styleSlot);
    const mountPoint = document.createElement('div');
    const stylePoint = document.createElement('div');
    this.styleSlot.appendChild(stylePoint);
    this.styleSlot.appendChild(mountPoint);

    this.cachePoint = createCache({
      key: `web-component-${this.name.toLowerCase().replace(/[^a-z]/g, '')}`,
      container: stylePoint,
    });

    this.componentRoot = ReactDOM.createRoot(mountPoint);
    this.render();
  }

  static get observedAttributes(): string[] {
    return [
      // used in share-component and book-component
      'recipe',
      // used in share-component
      'hide-share-button',
      'show-modal',
    ];
  }

  attributeChangedCallback() {
    this.render();
  }

  render() {
    const attributes = this.getAttributeNames().reduce((acc, name) => {
      return {
        ...acc,
        [camelCase(name)]:
          this.getAttribute(name) === '' ? true : this.getAttribute(name),
      };
    }, {});

    this.componentRoot &&
      this.componentRoot.render(
        this.cachePoint && (
          <CacheProvider value={this.cachePoint}>
            {Object.keys(attributes).length > 0 ? (
              <this.reactComponent {...attributes} />
            ) : (
              <this.reactComponent />
            )}
          </CacheProvider>
        )
      );
  }
}
