// NOTE: Only add here if you need translations
//       to be available from the widgets, as the widgets
//       cannot access Sanity CMS for translations!
export const fi = {
  'recipe.pax.adult_one': 'aikuinen',
  'recipe.pax.adult_plural': 'aikuista',
  'recipe.pax.child_one': 'lapsi',
  'recipe.pax.child_plural': 'lasta',
  'categories.multiStopText': 'Useita pysähdyksiä',
  'price.total': 'Alkaen',
  'price.shortFrom': 'Alkaen',
  'price.example': 'Hintaesimerkki:',
  'price.pricePerPerson': 'Hinta per henkilö',
  'ui.action.share': 'Jaa',
  'ui.action.book': 'Varaa',
  'ui.action.view': 'Näytä',
  'ui.action.width': 'Leveys',
  'ui.action.style': 'Tyyli',
  'ui.action.preview': 'Esikatselu',
  'ui.action.copy_link': 'Kopioi linkki',
  'ui.action.copy_code': 'Kopioi koodi',
  'ui.action.explore_and_publish': 'Julkaise Marketplaceen',
  'ui.notice.copied': 'Kopioitu',
  'ui.instruction.paste_body': 'Kopioi sivun body-osioon',
  'ui.instruction.paste_head': 'Kopioi sivun head-osioon',
  'ui.label.widget_builder': 'Kopioi HTML-koodi',
  'day.single': 'päivä',
  'night.single': 'yö',
  'night.plural': 'yötä',
  'ui.action.close': 'Sulje',
  'ui.shareModal.header': 'Jaa & tallenna matkasi',
  'ui.shareModal.action.saveTrip.label': 'Tallenna matka',
  'ui.shareModal.action.saveTrip.saved': 'Matkasi tallennettiin.',
  'ui.action.logIn.header': 'Kirjaudu sisään jatkaaksesi',
  'ui.action.register.header': 'Luo tili',
  'ui.action.forgottenPassword.header': 'Nollaa salasana',
  'ui.action.register.firstName.label': 'Etunimi',
  'ui.action.register.firstName.placeholder': 'Etunimi',
  'ui.action.register.lastName.label': 'Sukunimi',
  'ui.action.register.lastName.placeholder': 'Sukunimi',
  'ui.action.register.name.requiredError': 'Syötä nimi',
  'ui.action.register.email.label': 'Sähköposti',
  'ui.action.register.email.placeholder': 'Sähköposti',
  'ui.action.register.email.requiredError': 'Syötä sähköposti',
  'ui.action.register.password.label': 'Salasana',
  'ui.action.register.password.placeholder': 'Salasana',
  'ui.action.register.password.requirements': 'Syötä salasana',
  'ui.action.register.password.requiredError': 'Syötä salasana',
  'ui.action.register.password.minLengthError': 'Anna vähintään 6 merkkiä',
  'ui.action.register.password.maxLengthError': 'Enintään 50 merkkiä',
  'ui.action.register.confirmPassword.label': 'Vahvista salasana',
  'ui.action.register.confirmPassword.placeholder': 'Vahvista salasana',
  'ui.action.register.confirmPassword.requiredError': 'Syötä salasana',
  'ui.action.register.confirmPassword.noMatchError': 'Salasanat eivät täsmää',
  'ui.action.register.newsletter.info':
    'Haluan saada uutiskirjeitä, joissa on inspiraatiota ja tarjouksia Sembosta',
  'ui.action.register.genericError':
    'Jotain meni pieleen, yritä myöhemmin uudelleen',
  'ui.action.register.termsAndConditions.info':
    'Klikkaamalla "Luo tili" hyväksyn',
  'ui.action.register.termsAndConditions.linkLabel': 'Ehdot',
  'ui.action.register.submitButton.label': 'Luo tili',
  'ui.action.register.submitButton.loading': 'Ladataan',
  'ui.action.register.logIn': 'Kirjaudu sisään',
  'ui.action.logIn.username.label': 'Sähköposti',
  'ui.action.logIn.username.placeholder': 'Sähköposti',
  'ui.action.logIn.username.requiredError': 'Syötä sähköposti',
  'ui.action.logIn.password.label': 'Salasana',
  'ui.action.logIn.password.placeholder': 'Salasana',
  'ui.action.logIn.password.requiredError': 'Syötä salasana',
  'ui.action.logIn.genericError': 'Virheellinen sähköpostiosoite tai salasana',
  'ui.action.logIn.forgotenPassword': 'Unohditko salasanasi?',
  'ui.action.logIn.submitButton.label': 'Kirjaudu sisään',
  'ui.action.logIn.submitButton.loading': 'Ladataan',
  'ui.action.logIn.register': 'Luo tili',
  'ui.action.forgottenPassword.requestSent':
    'Sähköposti, jossa on ohjeet salasanan nollaamiseksi, on nyt lähetetty.',
  'ui.action.forgottenPassword.clickToLoginText':
    'Napsauta alla olevaa painiketta kirjautuaksesi uudelleen sisään.',
  'ui.action.forgottenPassword.logIn': 'Kirjaudu sisään',
  'ui.action.forgottenPassword.email.label': 'Sähköposti',
  'ui.action.forgottenPassword.email.placeholder': 'Sähköposti',
  'ui.action.forgottenPassword.email.requiredError': 'Syötä sähköposti',
  'ui.action.forgottenPassword.genericError':
    'Jotain meni pieleen, yritä myöhemmin uudelleen',
  'ui.action.forgottenPassword.submitButton.label': 'Nollaa salasana',
  'ui.action.forgottenPassword.submitButton.loading': 'Ladataan',
  'ui.action.forgottenPassword.cancel': 'Peruuta',
  'ui.shareModal.action.saveTrip.testInfo':
    'Hei! Kehitämme tätä toimintoa. Tällä hetkellä voit tallentaa ja jatkaa matkaasi helposti napsauttamalla "Kopioi linkki" -painiketta. Kiitos ymmärryksestäsi!',
};
