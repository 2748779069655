import BookComponentType1 from '../../../../apps/explore/widgets/book-component-type-1/BookComponentType1';
import { WebComponent } from './WebComponent';

const name = 'book-component-type-1';

class BookWebComponent extends WebComponent {
  constructor() {
    super(name, BookComponentType1);
  }

  static register() {
    window.customElements.get(name) ||
      window.customElements.define(name, BookWebComponent);
  }
}

export default BookWebComponent;
