// NOTE: Only add here if you need translations
//       to be available from the widgets, as the widgets
//       cannot access Sanity CMS for translations!
export const hu = {
  'recipe.pax.adult_one': 'felnőtt',
  'recipe.pax.adult_plural': 'felnőttek',
  'recipe.pax.child_one': 'gyerek',
  'recipe.pax.child_plural': 'gyerekek',
  'categories.multiStopText': 'Több megálló',
  'price.total': 'Tól',
  'price.shortFrom': 'Tól',
  'price.example': 'Árpélda:',
  'price.pricePerPerson': 'Ár személyenként',
  'ui.action.share': 'Megosztás',
  'ui.action.book': 'Foglalás',
  'ui.action.view': 'Megtekintés',
  'ui.action.width': 'Szélesség',
  'ui.action.style': 'Stílus',
  'ui.action.preview': 'Előnézet',
  'ui.action.copy_link': 'Link másolása',
  'ui.action.copy_code': 'Kód másolása',
  'ui.action.explore_and_publish': 'Közzététel a Marketplace-en',
  'ui.notice.copied': 'Másolva',
  'ui.instruction.paste_body': 'Másolja az oldal body tagjébe',
  'ui.instruction.paste_head': 'Másolja az oldal head tagjébe',
  'ui.label.widget_builder': 'HTML másolása',
  'day.single': 'nap',
  'night.single': 'éjszaka',
  'night.plural': 'éjszakák',
  'ui.action.close': 'Bezár',
  'ui.shareModal.header': 'Ossza meg és mentse el az utazását',
  'ui.shareModal.action.saveTrip.label': 'Mentse el az utat',
  'ui.shareModal.action.saveTrip.saved': 'Az utazásod mentve lett.',
  'ui.action.logIn.header': 'Jelentkezz be a folytatáshoz',
  'ui.action.register.header': 'Fiók létrehozása',
  'ui.action.forgottenPassword.header': 'Jelszó visszaállítása',
  'ui.action.register.firstName.label': 'Keresztnév',
  'ui.action.register.firstName.placeholder': 'Keresztnév',
  'ui.action.register.lastName.label': 'Vezetéknév',
  'ui.action.register.lastName.placeholder': 'Vezetéknév',
  'ui.action.register.name.requiredError': 'Adja meg a nevét',
  'ui.action.register.email.label': 'E-mail',
  'ui.action.register.email.placeholder': 'E-mail',
  'ui.action.register.email.requiredError': 'Adja meg az e-mailt',
  'ui.action.register.password.label': 'Jelszó',
  'ui.action.register.password.placeholder': 'Jelszó',
  'ui.action.register.password.requirements': 'Adja meg a jelszót',
  'ui.action.register.password.requiredError': 'Adja meg a jelszót',
  'ui.action.register.password.minLengthError':
    'Adjon meg legalább 6 karaktert',
  'ui.action.register.password.maxLengthError': 'Max 50 karakter',
  'ui.action.register.confirmPassword.label': 'Jelszó megerősítése',
  'ui.action.register.confirmPassword.placeholder': 'Jelszó megerősítése',
  'ui.action.register.confirmPassword.requiredError': 'Adja meg a jelszót',
  'ui.action.register.confirmPassword.noMatchError': 'A jelszavak nem egyeznek',
  'ui.action.register.newsletter.info':
    'Szeretnék hírleveleket kapni inspirációval és ajánlatokkal a Sembótól',
  'ui.action.register.genericError':
    'Valami hiba történt, kérjük, próbálkozzon újra később',
  'ui.action.register.termsAndConditions.info':
    'A "Fiók létrehozása" gombra kattintva elfogadom',
  'ui.action.register.termsAndConditions.linkLabel': 'Feltételek',
  'ui.action.register.submitButton.label': 'Fiók létrehozása',
  'ui.action.register.submitButton.loading': 'Betöltés',
  'ui.action.register.logIn': 'Bejelentkezés',
  'ui.action.logIn.username.label': 'E-mail',
  'ui.action.logIn.username.placeholder': 'E-mail',
  'ui.action.logIn.username.requiredError': 'Adja meg az e-mailt',
  'ui.action.logIn.password.label': 'Jelszó',
  'ui.action.logIn.password.placeholder': 'Jelszó',
  'ui.action.logIn.password.requiredError': 'Adja meg a jelszót',
  'ui.action.logIn.genericError': 'Helytelen e-mail cím vagy jelszó',
  'ui.action.logIn.forgotenPassword': 'Elfelejtette a jelszavát?',
  'ui.action.logIn.submitButton.label': 'Bejelentkezés',
  'ui.action.logIn.submitButton.loading': 'Betöltés',
  'ui.action.logIn.register': 'Fiók létrehozása',
  'ui.action.forgottenPassword.requestSent':
    'Most elküldtünk egy e-mailt a jelszó visszaállításának instrukcióival.',
  'ui.action.forgottenPassword.clickToLoginText':
    'Kattintson az alábbi gombra, hogy újra bejelentkezzen.',
  'ui.action.forgottenPassword.logIn': 'Bejelentkezés',
  'ui.action.forgottenPassword.email.label': 'E-mail',
  'ui.action.forgottenPassword.email.placeholder': 'E-mail',
  'ui.action.forgottenPassword.email.requiredError': 'Adja meg az e-mailt',
  'ui.action.forgottenPassword.genericError':
    'Valami hiba történt, kérjük, próbálkozzon újra később',
  'ui.action.forgottenPassword.submitButton.label': 'Jelszó visszaállítása',
  'ui.action.forgottenPassword.submitButton.loading': 'Betöltés',
  'ui.action.forgottenPassword.cancel': 'Mégsem',
  'ui.shareModal.action.saveTrip.testInfo':
    'Szia! Fejlesztés alatt áll ez a funkció. Jelenleg egyszerűen mentheti és folytathatja utazását a "Link másolása" gombra kattintva. Köszönjük a megértését!',
};
