// NOTE: Only add here if you need translations
//       to be available from the widgets, as the widgets
//       cannot access Sanity CMS for translations!
export const nl = {
  'recipe.pax.adult_one': 'volwassen',
  'recipe.pax.adult_plural': 'volwassenen',
  'recipe.pax.child_one': 'kind',
  'recipe.pax.child_plural': 'kinderen',
  'categories.multiStopText': 'Multi-stop',
  'price.total': 'Van',
  'price.shortFrom': 'Van',
  'price.example': 'Prijsvoorbeeld:',
  'price.pricePerPerson': 'Prijs per persoon',
  'ui.action.share': 'Deel',
  'ui.action.book': 'Boek',
  'ui.action.view': 'Weergave',
  'ui.action.width': 'Breedte',
  'ui.action.style': 'Stijl',
  'ui.action.preview': 'Voorbeeld',
  'ui.action.copy_link': 'Kopieer link',
  'ui.action.copy_code': 'Code kopiëren',
  'ui.action.explore_and_publish': 'Publiceer op Marktplaats',
  'ui.notice.copied': 'Gekopieerd',
  'ui.instruction.paste_body': 'Kopieer naar de body-tag van uw pagina',
  'ui.instruction.paste_head': 'Kopieer naar de head-tag van uw pagina',
  'ui.label.widget_builder': 'Embed',
  'day.single': 'dag',
  'night.single': 'nacht',
  'night.plural': 'nachten',
  'ui.action.close': 'Sluiten',
  'ui.shareModal.header': 'Deel & bewaar je reis',
  'ui.shareModal.action.saveTrip.label': 'De reis opslaan',
  'ui.shareModal.action.saveTrip.saved': 'Je reis is opgeslagen.',
  'ui.action.logIn.header': 'Log in om verder te gaan',
  'ui.action.register.header': 'Account aanmaken',
  'ui.action.forgottenPassword.header': 'Wachtwoord resetten',
  'ui.action.register.firstName.label': 'Voornaam',
  'ui.action.register.firstName.placeholder': 'Voornaam',
  'ui.action.register.lastName.label': 'Achternaam',
  'ui.action.register.lastName.placeholder': 'Achternaam',
  'ui.action.register.name.requiredError': 'Voer naam in',
  'ui.action.register.email.label': 'E-mail',
  'ui.action.register.email.placeholder': 'E-mail',
  'ui.action.register.email.requiredError': 'Voer e-mail in',
  'ui.action.register.password.label': 'Wachtwoord',
  'ui.action.register.password.placeholder': 'Wachtwoord',
  'ui.action.register.password.requirements': 'Voer wachtwoord in',
  'ui.action.register.password.requiredError': 'Voer wachtwoord in',
  'ui.action.register.password.minLengthError': 'Voer minimaal 6 tekens in',
  'ui.action.register.password.maxLengthError': 'Max. 50 tekens',
  'ui.action.register.confirmPassword.label': 'Bevestig wachtwoord',
  'ui.action.register.confirmPassword.placeholder': 'Bevestig wachtwoord',
  'ui.action.register.confirmPassword.requiredError': 'Voer wachtwoord in',
  'ui.action.register.confirmPassword.noMatchError':
    'De wachtwoorden komen niet overeen',
  'ui.action.register.newsletter.info':
    'Ik wil nieuwsbrieven ontvangen met inspiratie en aanbiedingen van Sembo',
  'ui.action.register.genericError':
    'Er is iets misgegaan, probeer het later opnieuw',
  'ui.action.register.termsAndConditions.info':
    'Door op "Account aanmaken" te klikken accepteer ik',
  'ui.action.register.termsAndConditions.linkLabel': 'Voorwaarden',
  'ui.action.register.submitButton.label': 'Account aanmaken',
  'ui.action.register.submitButton.loading': 'Laden',
  'ui.action.register.logIn': 'Inloggen',
  'ui.action.logIn.username.label': 'E-mail',
  'ui.action.logIn.username.placeholder': 'E-mail',
  'ui.action.logIn.username.requiredError': 'Voer e-mail in',
  'ui.action.logIn.password.label': 'Wachtwoord',
  'ui.action.logIn.password.placeholder': 'Wachtwoord',
  'ui.action.logIn.password.requiredError': 'Voer wachtwoord in',
  'ui.action.logIn.genericError': 'Onjuist e-mailadres of wachtwoord',
  'ui.action.logIn.forgotenPassword': 'Wachtwoord vergeten?',
  'ui.action.logIn.submitButton.label': 'Inloggen',
  'ui.action.logIn.submitButton.loading': 'Laden',
  'ui.action.logIn.register': 'Account aanmaken',
  'ui.action.forgottenPassword.requestSent':
    'Er is nu een e-mail verzonden met instructies om het wachtwoord te resetten.',
  'ui.action.forgottenPassword.clickToLoginText':
    'Klik op de knop hieronder om opnieuw in te loggen.',
  'ui.action.forgottenPassword.logIn': 'Inloggen',
  'ui.action.forgottenPassword.email.label': 'E-mail',
  'ui.action.forgottenPassword.email.placeholder': 'E-mail',
  'ui.action.forgottenPassword.email.requiredError': 'Voer e-mail in',
  'ui.action.forgottenPassword.genericError':
    'Er is iets misgegaan, probeer het later opnieuw',
  'ui.action.forgottenPassword.submitButton.label': 'Wachtwoord resetten',
  'ui.action.forgottenPassword.submitButton.loading': 'Laden',
  'ui.action.forgottenPassword.cancel': 'Annuleren',
  'ui.shareModal.action.saveTrip.testInfo':
    'Hallo! We zijn deze functie aan het ontwikkelen. Op dit moment kunt u uw reis gemakkelijk opslaan en hervatten door op de knop "Link kopiëren" te klikken. Bedankt voor uw begrip!',
};
