// NOTE: Only add here if you need translations
//       to be available from the widgets, as the widgets
//       cannot access Sanity CMS for translations!
export const pl = {
  'recipe.pax.adult_one': 'dorosły',
  'recipe.pax.adult_plural': 'dorośli',
  'recipe.pax.child_one': 'dziecko',
  'recipe.pax.child_plural': 'dzieci',
  'categories.multiStopText': 'Multi-stop',
  'price.total': 'Od',
  'price.shortFrom': 'Od',
  'price.example': 'Przykładowa cena:',
  'price.pricePerPerson': 'Cena za osobę',
  'ui.action.share': 'Udostępnij',
  'ui.action.book': 'Zarezerwuj',
  'ui.action.view': 'Zobacz',
  'ui.action.width': 'Szerokość',
  'ui.action.style': 'Styl',
  'ui.action.preview': 'Podgląd',
  'ui.action.copy_link': 'Skopiuj link',
  'ui.action.copy_code': 'Skopiuj kod',
  'ui.action.explore_and_publish': 'Opublikuj',
  'ui.notice.copied': 'Skopiowano',
  'ui.instruction.paste_body': 'Wklej do sekcji body na swojej stronie',
  'ui.instruction.paste_head': 'Wklej do sekcji head na swojej stronie',
  'ui.label.widget_builder': 'Skopiuj kod HTML',
  'day.single': 'dzień',
  'night.single': 'noc',
  'night.plural': 'noce',
  'ui.action.close': 'Zamknij',
  'ui.shareModal.header': 'Zapisz i udostępnij swoją podróż',
  'ui.shareModal.action.saveTrip.label': 'Zapisz podróż',
  'ui.shareModal.action.saveTrip.saved': 'Twoja podróż została zapisana.',
  'ui.action.logIn.header': 'Zaloguj się, aby kontynuować',
  'ui.action.register.header': 'Utwórz konto',
  'ui.action.forgottenPassword.header': 'Zresetuj hasło',
  'ui.action.register.firstName.label': 'Imię',
  'ui.action.register.firstName.placeholder': 'Imię',
  'ui.action.register.lastName.label': 'Nazwisko',
  'ui.action.register.lastName.placeholder': 'Nazwisko',
  'ui.action.register.name.requiredError': 'Wprowadź imię',
  'ui.action.register.email.label': 'E-mail',
  'ui.action.register.email.placeholder': 'E-mail',
  'ui.action.register.email.requiredError': 'Wprowadź email',
  'ui.action.register.password.label': 'Hasło',
  'ui.action.register.password.placeholder': 'Hasło',
  'ui.action.register.password.requirements': 'Wprowadź hasło',
  'ui.action.register.password.requiredError': 'Wprowadź hasło',
  'ui.action.register.password.minLengthError': 'Wprowadź co najmniej 6 znaków',
  'ui.action.register.password.maxLengthError': 'Maksymalnie 50 znaków',
  'ui.action.register.confirmPassword.label': 'Potwierdź hasło',
  'ui.action.register.confirmPassword.placeholder': 'Potwierdź hasło',
  'ui.action.register.confirmPassword.requiredError': 'Wprowadź hasło',
  'ui.action.register.confirmPassword.noMatchError': 'Hasła nie są zgodne',
  'ui.action.register.newsletter.info':
    'Chcę otrzymywać newslettery z inspiracjami i ofertami od Sembo',
  'ui.action.register.genericError':
    'Coś poszło nie tak, spróbuj ponownie później',
  'ui.action.register.termsAndConditions.info':
    'Klikając "Utwórz konto" akceptuję',
  'ui.action.register.termsAndConditions.linkLabel': 'warunki',
  'ui.action.register.submitButton.label': 'Utwórz konto',
  'ui.action.register.submitButton.loading': 'Ładowanie',
  'ui.action.register.logIn': 'Zaloguj się',
  'ui.action.logIn.username.label': 'E-mail',
  'ui.action.logIn.username.placeholder': 'E-mail',
  'ui.action.logIn.username.requiredError': 'Wprowadź email',
  'ui.action.logIn.password.label': 'Hasło',
  'ui.action.logIn.password.placeholder': 'Hasło',
  'ui.action.logIn.password.requiredError': 'Wprowadź hasło',
  'ui.action.logIn.genericError': 'Niepoprawny adres e-mail lub hasło',
  'ui.action.logIn.forgotenPassword': 'Zapomniałeś hasła?',
  'ui.action.logIn.submitButton.label': 'Zaloguj się',
  'ui.action.logIn.submitButton.loading': 'Ładowanie',
  'ui.action.logIn.register': 'Utwórz konto',
  'ui.action.forgottenPassword.requestSent':
    'Wysłaliśmy email z instrukcjami jak zresetować hasło.',
  'ui.action.forgottenPassword.clickToLoginText':
    'Kliknij poniższy przycisk, aby zalogować się ponownie.',
  'ui.action.forgottenPassword.logIn': 'Zaloguj się',
  'ui.action.forgottenPassword.email.label': 'E-mail',
  'ui.action.forgottenPassword.email.placeholder': 'E-mail',
  'ui.action.forgottenPassword.email.requiredError': 'Wprowadź email',
  'ui.action.forgottenPassword.genericError':
    'Coś poszło nie tak, spróbuj ponownie później',
  'ui.action.forgottenPassword.submitButton.label': 'Zresetuj hasło',
  'ui.action.forgottenPassword.submitButton.loading': 'Ładowanie',
  'ui.action.forgottenPassword.cancel': 'Anuluj',
  'ui.shareModal.action.saveTrip.testInfo':
    'Cześć! Rozwijamy tę funkcję. Na razie możesz łatwo zapisać i wznowić swoją podróż, klikając przycisk "Kopiuj link". Dziękujemy za zrozumienie!',
};
