import styled from '@emotion/styled';

type ImageContainerPropType = {
  numberOfItems: number;
};

const ImageContainer = styled.div<ImageContainerPropType>`
  width: 95%;
  height: 60%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  border-radius: 15px;
  overflow: hidden;
  flex-direction: column;
  ${({ numberOfItems }) => {
    if (numberOfItems === 1) {
      return `
            > :first-of-type{
              width:100%;
              height: 100%;
              border-radius: 15px;
            }
          `;
    }
    if (numberOfItems === 2) {
      return `
         > :first-of-type{
          width: 49%;
          height: 100%;
          border-radius: 15px 0 0 15px;
          border-right: 1.5px solid var(--color-white);
         }
         > :nth-of-type(2){
          width:49%;
          height: 100%;
          border-radius: 0 15px 15px 0;
          border-left: 1.5px solid var(--color-white);
         }
        `;
    }
    if (numberOfItems === 3) {
      return `
          > :first-of-type{
          width: 49%;
          height: 100%;
          border-radius: 15px 0 0 15px;
          border-right: 1.5px solid var(--color-white);
         }
         > :nth-of-type(2){
          width:49%;
          height: 49%;
          border-radius: 0 15px 0 0;
          border-left: 1.5px solid var(--color-white);
          border-bottom: 1.5px solid var(--color-white);
         }
         > :nth-of-type(3){
          width:49%;
          height: 49%;
          border-radius: 0 0px 15px 0;
          border-left: 1.5px solid var(--color-white);
          border-top: 1.5px solid var(--color-white);
         }
        `;
    }
    if (numberOfItems === 4) {
      return `
        > :first-of-type{
          width: 49%;
          height: 49%;
          border-radius: 15px 0 0 0;
          border-right: 1.5px solid var(--color-white);
          border-bottom: 1.5px solid var(--color-white);
         }
         > :nth-of-type(2){
          width:49%;
          height: 49%;
          border-radius: 0 0 0 15px;
          border-right: 1.5px solid var(--color-white);
          border-top: 1.5px solid var(--color-white);
         }
         > :nth-of-type(3){
          width:49%;
          height: 49%;
          border-radius: 0 15px 0 0;
          border-left: 1.5px solid var(--color-white);
          border-bottom: 1.5px solid var(--color-white);
         }
         > :nth-of-type(4){
          width:49%;
          height: 49%;
          border-radius: 0 0 15px 0;
          border-left: 1.5px solid var(--color-white);
          border-top: 1.5px solid var(--color-white);
         }
        `;
    }
    return `
      `;
  }}
`;

export default ImageContainer;
