import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary';
import { generateRecipeTitle } from '../../dtos/own/helpers';
import { decodeJsonRecipe } from '../../dtos/smart-search/helpers';
import { InspireProvider } from '../hooks/super-hook';
import GlobalStyles from '../share-component/styles/GlobalStyles';
import { sanitize } from './html/htmlParser';
import RecipeCard, { RecipeCardPropType } from './RecipeCard';
import { ComponentContainer } from './styles';

type FeatureToggle = 'enablePrice';

type PropType = {
  trips: string;
  url?: string;
  hidebutton?: boolean;
  features?: FeatureToggle[];
  className?: string;
};

export default function BookComponentType2({
  trips,
  url,
  hidebutton = false,
  className,
}: PropType) {
  const recipesWithTitle = parseTripsAsRecipies(trips);

  return (
    <InspireProvider
      componentName="BookComponentType2"
      metricsGateway="http://localhost:48080/metrics"
    >
      <ComponentContainer className={className}>
        {recipesWithTitle &&
          recipesWithTitle.map(
            ({ recipe, titleToShow, customDescription }, i) => (
              <ErrorBoundary key={`${i}-${titleToShow}`}>
                <GlobalStyles />
                <RecipeCard
                  className="recipe-card"
                  {...recipe}
                  images={recipe.images ?? []}
                  url={recipe.url ?? url}
                  customDescription={customDescription}
                  titleToShow={titleToShow}
                  hideButton={hidebutton}
                />
              </ErrorBoundary>
            )
          )}
      </ComponentContainer>
    </InspireProvider>
  );
}

function parseTripsAsRecipies(trips: string) {
  const recipies = Array.isArray(JSON.parse(trips))
    ? (JSON.parse(decodeJsonRecipe(trips)) as RecipeCardPropType[])
    : ([JSON.parse(decodeJsonRecipe(trips))] as RecipeCardPropType[]);
  recipies.forEach((r) => {
    // TODO: We should renew recipes to not contain startDate and endDate instead
    delete r.recipe.startDate;
    delete r.recipe.endDate;

    if (typeof r?.recipe?.suggestedBy === 'string') {
      // TODO: It seems suggestedBy is uppercase in all recipes now, need to hack the older recipes
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      r.recipe.suggestedBy = r.recipe.suggestedBy.toUpperCase();
    }

    // TODO: This seems to be required to send a recipe to TP, otherwise we get zero results
    r.recipe.featureToggles = [];
    r.recipe.skipCache = false;

    if (r.recipe?.ingredients?.length && r.recipe?.ingredients?.length > 0) {
      r?.recipe?.ingredients?.forEach((ingredient) => {
        if (typeof ingredient?.ingredientType === 'string') {
          // TODO: It seems ingredientType is uppercase in all recipes now, need to hack the older recipes
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ingredient.ingredientType = ingredient.ingredientType.toUpperCase();
        }

        if (
          ingredient?.heartedHotels &&
          Array.isArray(ingredient?.heartedHotels) &&
          ingredient?.heartedHotels?.length > 0
        ) {
          // TODO: Setting hotelCodes is a hack because Smartsearch doesn't use heartedHotels when calculating dates
          //       and TP will hack in this hotelCodes to the recipe. If we don't do the same
          //       there will be price mismatches because smartsearch will return one date for the widget
          //       but then a different date inside Trip Planner
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ingredient.hotelCodes = ingredient.heartedHotels
            .map((heartedHotel) => heartedHotel?.code)
            .filter((code) => code !== undefined);
        }
      });
    }
  });

  const recipesWithTitle = recipies?.map((recipe) => ({
    recipe,
    customDescription:
      typeof recipe.customDescription === 'string'
        ? sanitize(recipe.customDescription)
        : undefined,
    titleToShow: recipe.customTitle || generateRecipeTitle(recipe.recipe),
  }));

  return recipesWithTitle;
}
