type Props = {
  color?: string;
  visible?: boolean;
};
export function StarIcon({ color = '#E1BC2F', visible = true }: Props) {
  return (
    <svg viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.4997 23.7462L22.206 27.1974C23.251 27.8299 24.5297 26.8949 24.2547 25.7124L22.7422 19.2224L27.7885 14.8499C28.7097 14.0524 28.2147 12.5399 27.0047 12.4437L20.3635 11.8799L17.7647 5.74742C17.2972 4.63367 15.7022 4.63367 15.2347 5.74742L12.636 11.8662L5.9947 12.4299C4.7847 12.5262 4.2897 14.0387 5.21095 14.8362L10.2572 19.2087L8.7447 25.6987C8.4697 26.8812 9.74845 27.8162 10.7935 27.1837L16.4997 23.7462Z"
        fill={color}
        fillOpacity={visible ? '1.0' : '0.0'}
      />
    </svg>
  );
}
