import styled from '@emotion/styled';

const BookButton = styled.button`
  background-color: var(--color-primary-55);
  border-radius: var(--border-radius-l);
  width: 89px;
  border: none;
  padding: var(--spacing-8);
  color: var(--color-white);
  cursor: pointer;
  font: var(--font-label-xl-bold);
`;
export default BookButton;
