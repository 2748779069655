// NOTE: Only add here if you need translations
//       to be available from the widgets, as the widgets
//       cannot access Sanity CMS for translations!
export const no = {
  'recipe.pax.adult_one': 'voksen',
  'recipe.pax.adult_plural': 'voksne',
  'recipe.pax.child_one': 'barn',
  'recipe.pax.child_plural': 'barn',
  'categories.multiStopText': 'Flere stopp',
  'price.total': 'Totalt',
  'price.shortFrom': 'Fra',
  'price.example': 'Priseksempel:',
  'price.pricePerPerson': 'Pris per person',
  'ui.action.share': 'Dele',
  'ui.action.book': 'Bestill',
  'ui.action.view': 'Vis',
  'ui.action.width': 'Bredde',
  'ui.action.style': 'Stil',
  'ui.action.preview': 'Forhåndsvisning',
  'ui.action.copy_link': 'Kopier lenke',
  'ui.action.copy_code': 'Kopier kode',
  'ui.action.explore_and_publish': 'Publiser på markedsplassen',
  'ui.notice.copied': 'Kopiert',
  'ui.instruction.paste_body': 'Lim inn i body-taggen på siden din',
  'ui.instruction.paste_head': 'Lim inn i head-taggen på siden din',
  'ui.label.widget_builder': 'Widget-bygger',
  'day.single': 'dag',
  'night.single': 'natt',
  'night.plural': 'netter',
  'ui.action.close': 'Lukk',
  'ui.shareModal.header': 'Del & lagre turen din',
  'ui.shareModal.action.saveTrip.label': 'Lagre turen',
  'ui.shareModal.action.saveTrip.saved': 'Turen din ble lagret.',
  'ui.action.logIn.header': 'Logg inn for å fortsette',
  'ui.action.register.header': 'Opprett konto',
  'ui.action.forgottenPassword.header': 'Tilbakestill passord',
  'ui.action.register.firstName.label': 'Fornavn',
  'ui.action.register.firstName.placeholder': 'Fornavn',
  'ui.action.register.lastName.label': 'Etternavn',
  'ui.action.register.lastName.placeholder': 'Etternavn',
  'ui.action.register.name.requiredError': 'Skriv inn navn',
  'ui.action.register.email.label': 'E-post',
  'ui.action.register.email.placeholder': 'E-post',
  'ui.action.register.email.requiredError': 'Skriv inn e-post',
  'ui.action.register.password.label': 'Passord',
  'ui.action.register.password.placeholder': 'Passord',
  'ui.action.register.password.requirements': 'Skriv inn passord',
  'ui.action.register.password.requiredError': 'Skriv inn passord',
  'ui.action.register.password.minLengthError': 'Skriv inn minst 6 tegn',
  'ui.action.register.password.maxLengthError': 'Maks. 50 tegn',
  'ui.action.register.confirmPassword.label': 'Bekreft passord',
  'ui.action.register.confirmPassword.placeholder': 'Bekreft passord',
  'ui.action.register.confirmPassword.requiredError': 'Skriv inn passord',
  'ui.action.register.confirmPassword.noMatchError':
    'Passordene stemmer ikke overens',
  'ui.action.register.newsletter.info':
    'Jeg vil motta nyhetsbrev med inspirasjon og tilbud fra Sembo',
  'ui.action.register.genericError':
    'Noe gikk galt, vennligst prøv igjen senere',
  'ui.action.register.termsAndConditions.info':
    'Ved å velge "Registrer deg" nedenfor godtar jeg',
  'ui.action.register.termsAndConditions.linkLabel': 'Betingelser',
  'ui.action.register.submitButton.label': 'Opprett konto',
  'ui.action.register.submitButton.loading': 'Laster',
  'ui.action.register.logIn': 'Logg inn',
  'ui.action.logIn.username.label': 'E-post',
  'ui.action.logIn.username.placeholder': 'E-post',
  'ui.action.logIn.username.requiredError': 'Skriv inn e-post',
  'ui.action.logIn.password.label': 'Passord',
  'ui.action.logIn.password.placeholder': 'Passord',
  'ui.action.logIn.password.requiredError': 'Skriv inn passord',
  'ui.action.logIn.genericError': 'Feil e-post eller passord',
  'ui.action.logIn.forgotenPassword': 'Glemt passordet?',
  'ui.action.logIn.submitButton.label': 'Logg inn',
  'ui.action.logIn.submitButton.loading': 'Laster',
  'ui.action.logIn.register': 'Opprett konto',
  'ui.action.forgottenPassword.requestSent':
    'Du har nå fått en e-post med instruksjoner om hvordan du tilbakestiller passordet ditt.',
  'ui.action.forgottenPassword.clickToLoginText':
    'Klikk på knappen nedenfor for å logge på igjen.',
  'ui.action.forgottenPassword.logIn': 'Logg inn',
  'ui.action.forgottenPassword.email.label': 'E-post',
  'ui.action.forgottenPassword.email.placeholder': 'E-post',
  'ui.action.forgottenPassword.email.requiredError': 'Skriv inn e-post',
  'ui.action.forgottenPassword.genericError':
    'Noe gikk galt, vennligst prøv igjen senere',
  'ui.action.forgottenPassword.submitButton.label': 'Tilbakestill passord',
  'ui.action.forgottenPassword.submitButton.loading': 'Laster',
  'ui.action.forgottenPassword.cancel': 'Avbryt',
  'ui.shareModal.action.saveTrip.testInfo':
    'Hei! Vi utvikler denne funksjonen. For øyeblikket kan du lagre og gjenoppta turen din enkelt ved å klikke på "Kopier link"-knappen. Takk for din forståelse!',
};
