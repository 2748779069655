import { RecipeQl } from '../../data/smart-search/hooks';
import { getRecipeData, getRecipeDescription } from '../../dtos/own/helpers';
import { getUrl } from '../../dtos/own/recipeHelpers';
import { ProductData } from '../../dtos/own/types';
import { useTranslate } from '../../hooks/useTranslate';
import { useCheapestItineraryWithProductDataAndMetrics } from '../hooks/super-hook';
import { ImageLoader, PriceLoader } from './icons';
import {
  BookButton,
  BookButtonContainer,
  BookContainer,
  CardTitle,
  DescriptionText,
  Image,
  ImageContainer,
  InfoContainer,
  Price,
  PriceContainer,
  PriceField,
  ProductCardContainer,
  SubPriceField,
} from './styles';

export type RecipeCardPropType = TripPropType & {
  titleToShow: string;
};

type TripPropType = {
  url: string;
  recipe: RecipeQl;
  hideButton?: boolean;
  customTitle?: string;
  images?: string[];
  customDescription?: string;
  customFromPriceText?: string;
  customPriceQualifier?: string;
  className?: string;
  products?: ProductData[];
};

export default function RecipeCard({
  url,
  recipe,
  titleToShow,
  customDescription,
  images,
  hideButton,
  customFromPriceText,
  customPriceQualifier,
}: RecipeCardPropType) {
  const { t } = useTranslate();

  const { cheapestItineraryData, productData, loadingItineraryData } =
    useCheapestItineraryWithProductDataAndMetrics({
      recipe,
      widgetTitle: titleToShow,
    });

  const bestPrice =
    cheapestItineraryData?.cheapestItineraryWithStatus?.itinerary ?? null;
  const onViewButtonClick = () => {
    // TODO: Do we want to start passing the recipe to TP instead perhaps?
    const recipeUrl = getUrl(recipe);
    window.open(recipeUrl, '_blank');

    // window.open(addGAParameter(url), '_blank');
  };

  const recipeData = getRecipeData(recipe);
  const tripDetails = getRecipeDescription(recipeData, {
    night: {
      single: t('night.single'),
      plural: t('night.plural'),
    },
    adult: {
      single: t('recipe.pax.adult_one'),
      plural: t('recipe.pax.adult_plural'),
    },
    child: {
      single: t('recipe.pax.child_one'),
      plural: t('recipe.pax.child_plural'),
    },
  });

  return bestPrice || loadingItineraryData ? (
    <ProductCardContainer key={url} className="recipe-container">
      {images && images.length > 0 ? (
        <ImageContainer
          className="recipe-image-container"
          numberOfItems={images.length}
        >
          {images.slice(0, 4).map((img) => (
            <Image className="recipe-image" key={`image-${img}`} src={img} />
          ))}
        </ImageContainer>
      ) : (
        <ImageContainer
          className="recipe-image-container"
          numberOfItems={productData?.length ?? 0}
        >
          {(productData &&
            productData[0] &&
            productData
              .slice(0, 4)
              .map((product) => (
                <Image
                  className="recipe-image"
                  key={`image-${product.imageURL}`}
                  alt={product.name}
                  src={product.imageURL ?? ''}
                />
              ))) || <ImageLoader />}
        </ImageContainer>
      )}
      <InfoContainer className="recipe-info-container">
        <div className="recipe-info-details">
          <CardTitle className="recipe-info-title">
            {titleToShow ||
              (productData && productData[0] && productData[0].name)}
          </CardTitle>
          <DescriptionText
            className="recipe-info-description"
            dangerouslySetInnerHTML={{
              __html: customDescription ?? tripDetails ?? '',
            }}
          />
        </div>
        <BookContainer className="recipe-book-container">
          <PriceContainer className="recipe-book-price-container">
            <PriceField className="recipe-book-price-field">
              {customFromPriceText || t('price.total')}{' '}
              {(bestPrice && (
                <Price className="recipe-book-price">{`${bestPrice.totalPrice?.amount.toLocaleString(
                  navigator?.language ?? 'en',
                  {
                    style: 'currency',
                    currency: bestPrice.totalPrice?.currency ?? 'undefined',
                    maximumFractionDigits: 0,
                  }
                )}`}</Price>
              )) || <PriceLoader />}
            </PriceField>
            <SubPriceField className="recipe-book-sub-price-field">
              {customPriceQualifier || tripDetails}
            </SubPriceField>
          </PriceContainer>
          {!hideButton && (
            <BookButtonContainer className="recipe-book-btn-container">
              <BookButton
                className="recipe-book-btn"
                onClick={onViewButtonClick}
              >
                {t('ui.action.view')}
              </BookButton>
            </BookButtonContainer>
          )}
        </BookContainer>
      </InfoContainer>
    </ProductCardContainer>
  ) : null;
}
