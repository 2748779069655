import styled from '@emotion/styled';

const BookButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #252525;
  color: var(--color-white);
  border: 0;
  border-radius: 7%;
  font-size: 1em;
  width: 30%;
  min-width: 120px;
  cursor: pointer;
  padding: 2%;
  margin-top: 2%;

  &:hover {
    opacity: 0.9;
    transform: scale(1.1);
  }
`;
export default BookButton;
