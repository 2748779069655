import styled from '@emotion/styled';

const InfoContainer = styled.div`
  width: 80%;
  height: 36%;
  margin: 0 auto;
  background-color: var(--color-white);
  color: var(--color-black);
  padding: 13px 15px 15px;
  border-radius: 15px;
  position: absolute;
  top: 53%;
  box-shadow: 2px 7px 15px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export default InfoContainer;
