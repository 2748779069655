export class ErrorText {
  static smartSearchFailed = `
There was an error requesting this data.
Please contact the Sembo Offers and Insights team if this problem persists.
`;

  static contentApiFailed = `
There was an error requesting this data.
Please contact the Sembo Content team if this problem persists.
`;

  static noAvailability = `
This recipe has no availability.
Try adjusting parameters in Trip Planner to get availability or removing date constraints.
`;
}
