type PropType = {
  line1: string;
  line2?: string;
};

export default function PreviewCardText({ line1, line2 = '' }: PropType) {
  return (
    <svg viewBox="0 0 260 43">
      <text
        fill="#f8f9fb"
        fontSize="18px"
        fontWeight="600"
        fontFamily="Roboto, sans-serif"
      >
        <tspan x="7" y="16">
          {line1}
        </tspan>
        <tspan x="7" y="36">
          {line2}
        </tspan>
      </text>
    </svg>
  );
}
