import React, { ErrorInfo, ReactNode } from 'react';

type PropType = {
  children: ReactNode;
};

type StateType = {
  hasError: boolean;
};

export class ErrorBoundary extends React.Component<PropType, StateType> {
  constructor(props: PropType) {
    super(props);
    this.state = { hasError: false };
  }

  public override componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ hasError: true });
  }

  override render() {
    if (this.state.hasError) {
      return null;
    }
    return this.props.children;
  }
}
