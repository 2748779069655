// TODO: This file must be broken appart and it's component
// moved to the correct lib

import {
  CMSMarketplaceTrip,
  ContentByIdMap,
  SanityUUID,
} from '../../data/sanity/types';
import {
  HotelIngredientResponseQl,
  IngredientQl,
  IngredientResponseQl,
  ItineraryResponseQl,
  TicketIngredientResponseQl,
} from '../../data/smart-search/hooks';
import { SemboMarket } from '../../utils/markets';
import { Airport, Hotel, ProductData, ProductType } from './types';

export const getProductType = (
  code: string,
  response: ItineraryResponseQl
): ProductType => {
  return response.result?.find((r) =>
    (r as HotelIngredientResponseQl).subIngredientResults?.find(
      (si) => (si as TicketIngredientResponseQl).code === code
    )
  )
    ? 'Ticket'
    : 'Hotel';
};

export type PlaceLevel = 'City' | 'Country' | 'Region';
export type Place = {
  PolygonId: number;
  PolygonName: string;
  PolygonFullName: string;
  UrlSlug: string;
  Type: PlaceLevel;
  PlaceCityName: string;
  PlaceRegionName: string;
  PlaceCountryName: string;
  LastModifiedDate: string;
  ImageNames: string[];
  Score: number;
};
export type PlaceFilterType = 'Country' | 'Region';
export type PlaceSortBy = 'slug' | 'score';
export type GetPlacesResponse = Place[];

export const getPlaces = async (
  market: SemboMarket,
  level?: PlaceLevel,
  filter?: {
    type: PlaceFilterType;
    value: string;
  },
  sortBy?: PlaceSortBy
): Promise<GetPlacesResponse> => {
  const baseUrl = 'https://content.sembo.com/places';
  const url = new URL(baseUrl);
  const params = new URLSearchParams();

  if (level) {
    params.append('type', level);
  }
  if (filter) {
    params.append(filter.type, filter.value);
  }
  if (sortBy) {
    params.append('sort', sortBy);
  }

  url.search = params.toString();

  return (
    await fetch(url.toString(), {
      method: 'GET',
      headers: { 'X-Sembo-Host': market.host },
    })
  ).json();
};

export const getPlaceByPolygonId = async (
  market: SemboMarket,
  polygonId: number
): Promise<PlaceInformation | null> => {
  const url = `https://content.sembo.com/Place/PolygonId/${polygonId}`;
  const response = await fetch(url, {
    headers: {
      'X-Sembo-Host': market.host,
    },
  });

  if (!response.ok) {
    return null;
  }

  const data = await response.json();

  if (!data) {
    return null;
  }

  return data;
};

export type PlaceInformation = {
  status?: 404;
  Type: PlaceLevel;
  PlaceCityName: string | null;
  PlaceCountryName: string;
  PlaceRegionName: string;
  IataCode: string | null;
  Language: string;
  PolygonId: number;
  PolygonName: string;
  PolygonFullName: string;
  HeadingMarkup: string | null;
  BaseMarkup: string | null;
  HintsMarkup: string | null;
  CarMarkup: string | null;
  FlightMarkup: string | null;
  PreambleMarkup: string | null;
  ImageNames: string[];
  UrlSlug: string;
  CitySlug: string | null;
  RegionSlug: string | null;
  CountrySlug: string | null;
  IsoCountryCode: string;
  Boundaries?: {
    TopRight: {
      Latitude: number;
      Longitude: number;
    };
    LeftBottom: {
      Latitude: number;
      Longitude: number;
    };
  };
  Score: number;
};
export const getPlace = async (
  market: SemboMarket,
  placeSlug: string
): Promise<PlaceInformation> => {
  const url = 'https://content.sembo.com/Place/' + placeSlug;

  return (
    await fetch(url, {
      method: 'GET',
      headers: { 'X-Sembo-Host': market.host },
    })
  ).json();
};

export const getRedirect = async (
  market: SemboMarket,
  url: string
): Promise<{ Url: string | null; PolygonId: number | null }> => {
  return await (
    await fetch('https://content.sembo.com/Redirect', {
      method: 'POST',
      body: JSON.stringify({
        Url: url,
      }),
      headers: {
        'Content-Type': 'application/json',
        'X-Sembo-Host': market.host,
      },
    })
  ).json();
};

export const isSemboTravelImageCdn = (url: string) => {
  if (url.startsWith('/m/')) {
    return false;
  }

  if (url.includes('images.sembo.travel')) {
    return true;
  }

  try {
    new URL(url);
    return false;
  } catch (e) {
    // our cdn usually returns just a filename
    // so the try/catch will fail
    return true;
  }
};

export const getImageURL = (
  imageName: string,
  width?: number,
  height?: number
) => {
  const determinedHeight = height ?? 9999;

  if (!isSemboTravelImageCdn(imageName)) {
    const url = new URL(imageName);
    // most image sources support https, but we store them as http
    url.protocol = 'https:';

    return url.toString();
  }

  let imageUrl = imageName.includes('images.sembo.travel')
    ? imageName
    : `https://images.sembo.travel/ImageService/ImageHandler.ashx?service=sembo&nameOfImage=${imageName}&resizeMode=${
        height ? 'FitOutside' : 'FitInside'
      }`;
  if (width) imageUrl += `&width=${width}&height=${determinedHeight}`;

  return imageUrl;
};

export const getItineraryURLs = (code: string | string[]) => {
  const codes = Array.isArray(code) ? code : [code];

  return codes.map((c) => {
    return `${'https://content.sembo.com/Hotels/GetHotels'}?SearchText=hotel-code:${c}(max%3D1)&PerfectMatchOnly=true&Include=Hotels.HotelCode,Hotels.Name,Hotels.MainPolygon.FullName,Hotels.Images.ImageName,Hotels.SemboSunRating`;
  });
};

const id = <T>(x: T) => x;

const extractCode = (
  subIngredient: IngredientResponseQl | IngredientQl
): string => (subIngredient as IngredientQl).code ?? '';

function getCodesFromResult(
  r: HotelIngredientResponseQl,
  onlySpecifiedHotelCodes?: string[]
): string[] {
  const xs = [];
  if ('suggested' in r && r.suggested?.hotelCode) {
    if (
      !onlySpecifiedHotelCodes ||
      onlySpecifiedHotelCodes.includes(r.suggested.hotelCode)
    )
      xs.push(r.suggested.hotelCode);
  }
  if ('subIngredientResults' in r) {
    xs.push(
      (r.subIngredientResults as IngredientResponseQl[])
        ?.flatMap(extractCode)
        .filter(id)
    );
  }
  return xs.flat() as string[];
}

export function getCodes(
  itinerary: ItineraryResponseQl,
  onlySpecifiedHotelCodes?: string[]
): string[] {
  return (itinerary.result as IngredientResponseQl[]).flatMap((result) =>
    getCodesFromResult(result, onlySpecifiedHotelCodes)
  );
}

export async function getProductData(
  suggestion: ItineraryResponseQl,
  market: SemboMarket
): Promise<ProductData[]> {
  const codes = getCodes(suggestion);
  if (!codes || codes.length === 0) return [];
  const promises = codes
    .flatMap((value) =>
      getItineraryURLs(value).map((url) =>
        fetch(url, {
          method: 'GET',
          headers: { 'X-Sembo-Host': market.host },
        }).then(async (response) => {
          const result = await response.json();
          const { Name, Images, SemboSunRating, HotelCode, MainPolygon } =
            (result.Hotels && result.Hotels[0]) || {};
          const imgRef = Images && Images[0]?.ImageName;
          const imageURL = imgRef ? getImageURL(imgRef) : null;

          if (!imgRef) {
            console.warn(
              'Images[0]?.ImageName is returning undefined from fetching url',
              url
            );
          }

          const type = getProductType(HotelCode, suggestion);
          return {
            code: HotelCode,
            type,
            name: Name,
            imageURL,
            rating: SemboSunRating && Number(SemboSunRating),
            city: MainPolygon.FullName.split(',')[0].trim(),
            country: MainPolygon.FullName.split(',').slice(1).join().trim(),
          };
        })
      )
    )
    .flat();
  try {
    const result = await Promise.all(promises);
    return result;
  } catch (_e) {
    console.log(_e);
    return [];
  }
}

export const getHarbourByIata = async (iata: string, market: SemboMarket) => {
  const response = await fetch(
    `https://content.sembo.com/Autosuggestion?&Types=Harbour&Page=0&Size=1&Harbour=${iata}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Sembo-Host': market.host,
      },
    }
  );
  const data = await response.json();

  if (data?.length > 0) {
    return {
      name: data?.[0]?.harbour?.addresss ?? data?.[0]?.harbour?.address,
      city: data?.[0]?.harbour?.label,
    };
  }

  // no matches
  return {
    name: '',
    city: '',
  };
};

export const getAirportIataByNearestDestination = async (
  searchText: string,
  market: SemboMarket
): Promise<string | null> => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Sembo-Host': market.host,
    },
  };
  const response = await fetch(
    `https://content.sembo.com/Autosuggestion?Types=AirportForDestination&Page=0&Size=1&Term=${searchText}`,
    options
  );
  const data = await response.json();

  return data[0]?.airport?.code ?? null;
};

export const getAirportByIata = async (
  iata: string,
  market: SemboMarket
): Promise<Airport> => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Sembo-Host': market.host,
    },
  };
  const response = await fetch(
    `https://content.sembo.com/Autosuggestion?&Types=Airport&Page=0&Size=1&IataCode=${iata}`,
    options
  );
  const data = await response.json();

  return {
    name: data[0].airport.label,
    city: data[0].airport.city,
  };
};

export const getHotels = async (
  market: SemboMarket,
  searchOptions: {
    worldWide?: boolean;
    hotelCode?: string;
    searchFilters?: string[];
    polygonId?: string;
    searchText?: string;
    maxNumberOfHotels: number;
    useHistoricalCodes?: boolean;
  },
  includeFields: string[] = [],
  includeGroups: string[] = []
): Promise<any> => {
  const options: RequestInit = {
    method: 'GET',
    headers: { 'X-Sembo-Host': market.host },
  };

  const searchFiltersToUse = searchOptions.searchFilters ?? [];

  const generateSearchFilter = (filters: string[]) =>
    `%20(${filters.map((item) => encodeURIComponent(item)).join(';')})`;

  let searchQuery;
  if (searchOptions.worldWide) {
    searchFiltersToUse.push(`max=${searchOptions.maxNumberOfHotels}`);

    searchQuery = encodeURIComponent(
      `polygon:${JSON.stringify({
        type: 'polygon',
        coordinates: [
          [
            [-180, 90],
            [180, 90],
            [180, -90],
            [-180, -90],
            [-180, 90],
          ],
        ],
      })}`
    );
  } else if (searchOptions.hotelCode) {
    searchQuery = `hotel-code%3A${searchOptions.hotelCode}`;
  } else if (searchOptions.polygonId) {
    searchQuery = `polygon-id%3A${searchOptions.polygonId}`;
  } else if (searchOptions.searchText) {
    searchQuery = searchOptions.searchText;
  }

  if (searchOptions.searchFilters) {
    searchQuery += generateSearchFilter(searchFiltersToUse);
  }

  const baseUrl = 'https://content.sembo.com/Hotels/GetHotels';
  const queryParams = [
    `SearchText=${searchQuery}`,
    ...includeGroups.map((field) => `IncludeGroup=${field}`),
    ...includeFields.map((group) => `Include=${group}`),
    ...(searchOptions.useHistoricalCodes ? ['UseHistoricalCodes=true'] : []),
    `MaxNumberOfHotels=${searchOptions.maxNumberOfHotels}`,
    // For showing all attributes, not to be used in production
    // 'IncludeGroup=debug',
  ].join('&');

  const url = `${baseUrl}?${queryParams}`;

  return (await fetch(url, options)).json();
};

export const getHotelsByPolygonId = async (
  market: SemboMarket,
  polygonId: number
): Promise<Hotel[]> => {
  const options: RequestInit = {
    method: 'GET',
    headers: { 'X-Sembo-Host': market.host },
  };
  const baseUrl = 'https://content.sembo.com/Hotels/GetHotels';
  const baseQueryParams = [
    'Include=Hotels.HotelCode',
    'Include=Hotels.Name',
    'Include=Hotels.Images.ImageName',
    'Include=Hotels.MainPolygon.FullName',
    'Include=Hotels.HotelMainPolygonSlug',
    'Include=Hotels.HotelNameSlug',
    'Include=Hotels.SemboSunRating',
    'Include=Hotels.HasSembokFactSheetLinked',
    'UseHistoricalCodes=true',
  ].join('&');

  const url = `${baseUrl}?SearchText=polygon-id:${polygonId}&${baseQueryParams}&maxNumberOfHotels=50`;
  const res = await fetch(url, options);
  const results = await res.json();

  return (results?.Hotels ?? []) as Hotel[];
};

export type PolygonType =
  | 'city'
  | 'country'
  | 'high_level_region'
  | 'multi_city_vicinity'
  | 'neighborhood'
  | 'province_state'
  | 'system_polygon';

type ScoresMap = {
  [polygonId: string]: {
    Score: number;
    Type: PolygonType;
    AverageScore: number;
  };
};

export const addPopularityScoreToTrips = async (
  market: SemboMarket,
  trips: CMSMarketplaceTrip[]
): Promise<CMSMarketplaceTrip[]> => {
  const polygonIds: Set<number> = new Set();

  // collect the polygon ids
  trips.forEach(
    (trip) =>
      trip?.recipe?.ingredients
        ?.filter((ingredient) =>
          ingredient?.destination?.startsWith('polygon-id')
        )
        ?.forEach((ingredient) => {
          const polygonId = ingredient?.destination?.replace('polygon-id:', '');
          if (polygonId) {
            polygonIds.add(parseInt(polygonId));
          }
        }) ?? []
  );

  const scoresMap = await getPolygonsScore(market, Array.from(polygonIds));

  // update the highestIngredientScore for each trip
  const updatedTrips = trips.map((trip) => {
    const ingredientScores =
      trip?.recipe?.ingredients
        ?.filter((ingredient) =>
          ingredient?.destination?.startsWith('polygon-id')
        )
        ?.map((ingredient, index, arr) => {
          const polygonId = ingredient?.destination?.replace('polygon-id:', '');
          if (polygonId) {
            const polygonResult = scoresMap?.[polygonId];

            return polygonResult?.AverageScore ?? 0;
          }
          return 0;
        }) ?? [];

    return {
      ...trip,
      highestIngredientScore: Math.max(0, ...ingredientScores),
    };
  });

  return updatedTrips;
};

export const getPolygonsScore = async (
  market: SemboMarket,
  polygonIds: Array<number>
): Promise<ScoresMap> =>
  (
    await (
      await fetch('https://content.sembo.com/polygonsscore', {
        method: 'POST',
        body: JSON.stringify({
          PolygonIds: Array.from(polygonIds),
        }),
        headers: {
          'Content-Type': 'application/json',
          'X-Sembo-Host': market.host,
        },
      })
    ).json()
  )?.PolygonScores ?? {};

export const sortTripIdsByHighestIngredientScore = (
  tripIds: SanityUUID[],
  tripsMap: ContentByIdMap<CMSMarketplaceTrip>,
  prioritizedHighlights: string[] = [],
  direction: 'asc' | 'desc' = 'desc'
): SanityUUID[] =>
  tripIds.sort((a, b) => {
    const tripA = tripsMap[a];
    const tripB = tripsMap[b];

    const scoreA = tripA?.highestIngredientScore ?? 0;
    const scoreB = tripB?.highestIngredientScore ?? 0;

    const hasPriorityA =
      tripA?.highlight && prioritizedHighlights.includes(tripA?.highlight);
    const hasPriorityB =
      tripB?.highlight && prioritizedHighlights.includes(tripB?.highlight);

    if (hasPriorityA && !hasPriorityB) {
      return -1;
    }

    if (!hasPriorityA && hasPriorityB) {
      return 1;
    }

    // If both have prioritized labels or neither has, sort by score
    if (direction === 'asc') {
      return scoreA - scoreB;
    }
    return scoreB - scoreA;
  });
