import { SvgPropType } from './svgTypes';

export function ShoppingCartIcon({ color = 'white' }: SvgPropType) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 244 244"
      xmlSpace="preserve"
    >
      <g fill={color}>
        <path
          d="M244,156.153V45.026c0-4.971-4.029-9-9-9H46.331l-5.487-15.089c-1.293-3.557-4.674-5.925-8.458-5.925H9
		c-4.971,0-9,4.029-9,9s4.029,9,9,9h17.083L83.301,190.37c1.293,3.558,4.674,5.925,8.458,5.925h6.831
		c-1.855,3.206-2.923,6.922-2.923,10.885c0,12.024,9.783,21.808,21.808,21.808c12.025,0,21.809-9.783,21.809-21.808
		c0-3.963-1.068-7.679-2.923-10.885h66.946c-1.855,3.206-2.923,6.922-2.923,10.885c0,12.024,9.783,21.808,21.808,21.808
		c12.025,0,21.809-9.783,21.809-21.808c0-4.628-1.454-8.922-3.922-12.456c2.368-1.621,3.922-4.342,3.922-7.429c0-4.971-4.029-9-9-9
		H98.063l-4.779-13.142H235C239.971,165.153,244,161.124,244,156.153z M117.475,210.987c-2.1,0-3.808-1.708-3.808-3.808
		s1.708-3.809,3.808-3.809s3.809,1.709,3.809,3.809S119.574,210.987,117.475,210.987z M222.191,210.987
		c-2.1,0-3.808-1.708-3.808-3.808s1.708-3.809,3.808-3.809S226,205.08,226,207.18S224.291,210.987,222.191,210.987z
		 M126.475,109.794V91.385H170v18.409H126.475z M170,127.794v19.359h-43.525v-19.359H170z M73.161,109.811l-6.7-18.426h42.014
		v18.409H73.5C73.385,109.794,73.274,109.807,73.161,109.811z M126.475,73.385V54.026H170v19.358H126.475z M188,91.385h38v18.409
		h-38V91.385z M226,73.385h-38V54.026h38V73.385z M108.475,54.026v19.358H59.916l-7.039-19.358H108.475z M79.699,127.794h28.775
		v19.359H86.739L79.699,127.794z M188,147.153v-19.359h38v19.359H188z"
        />
      </g>
    </svg>
  );
}
