import ContentLoader from 'react-content-loader';

const PriceLoader = () => (
  <ContentLoader
    viewBox="0 0 700 160"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    height={20}
    width={100}
  >
    <rect width="100%" height="100%" />
  </ContentLoader>
);

export default PriceLoader;
