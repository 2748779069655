// NOTE: Only add here if you need translations
//       to be available from the widgets, as the widgets
//       cannot access Sanity CMS for translations!
export const fr = {
  'recipe.pax.adult_one': 'adulte',
  'recipe.pax.adult_plural': 'adultes',
  'recipe.pax.child_one': 'enfant',
  'recipe.pax.child_plural': 'enfants',
  'categories.multiStopText': 'Multi-arrêt',
  'price.total': 'À partir de',
  'price.shortFrom': 'À partir de',
  'price.example': 'Exemple de prix :',
  'price.pricePerPerson': 'Prix par personne',
  'ui.action.share': 'Partager',
  'ui.action.book': 'Réserver',
  'ui.action.view': 'Voir',
  'ui.action.width': 'Largeur',
  'ui.action.style': 'Style',
  'ui.action.preview': 'Aperçu',
  'ui.action.copy_link': 'Copier le lien',
  'ui.action.copy_code': 'Copier le code',
  'ui.action.explore_and_publish': 'Publier sur le Marketplace',
  'ui.notice.copied': 'Copié',
  'ui.instruction.paste_body': 'Copiez dans le corps de votre page',
  'ui.instruction.paste_head': 'Copiez dans l’en-tête de votre page',
  'ui.label.widget_builder': 'Copier le code HTML',
  'day.single': 'jour',
  'night.single': 'nuit',
  'night.plural': 'nuits',
  'ui.action.close': 'Fermer',
  'ui.shareModal.header': 'Partager & sauvegarder votre voyage',
  'ui.shareModal.action.saveTrip.label': 'Sauvegarder le voyage',
  'ui.shareModal.action.saveTrip.saved': 'Votre voyage a été enregistré.',
  'ui.action.logIn.header': 'Connectez-vous pour continuer',
  'ui.action.register.header': 'Créer un compte',
  'ui.action.forgottenPassword.header': 'Réinitialiser le mot de passe',
  'ui.action.register.firstName.label': 'Prénom',
  'ui.action.register.firstName.placeholder': 'Prénom',
  'ui.action.register.lastName.label': 'Nom de famille',
  'ui.action.register.lastName.placeholder': 'Nom de famille',
  'ui.action.register.name.requiredError': 'Entrez le nom',
  'ui.action.register.email.label': 'E-mail',
  'ui.action.register.email.placeholder': 'E-mail',
  'ui.action.register.email.requiredError': "Entrez l'email",
  'ui.action.register.password.label': 'Mot de passe',
  'ui.action.register.password.placeholder': 'Mot de passe',
  'ui.action.register.password.requirements': 'Entrez le mot de passe',
  'ui.action.register.password.requiredError': 'Entrez le mot de passe',
  'ui.action.register.password.minLengthError': 'Entrez au moins 6 caractères',
  'ui.action.register.password.maxLengthError': 'Max 50 caractères',
  'ui.action.register.confirmPassword.label': 'Confirmer le mot de passe',
  'ui.action.register.confirmPassword.placeholder': 'Confirmer le mot de passe',
  'ui.action.register.confirmPassword.requiredError': 'Entrez le mot de passe',
  'ui.action.register.confirmPassword.noMatchError':
    'Les mots de passe ne correspondent pas',
  'ui.action.register.newsletter.info':
    'Je veux recevoir des newsletters avec des inspirations et des offres de Sembo',
  'ui.action.register.genericError':
    'Quelque chose a mal tourné, veuillez réessayer plus tard',
  'ui.action.register.termsAndConditions.info':
    'En cliquant sur "Créer un compte", j\'accepte',
  'ui.action.register.termsAndConditions.linkLabel': 'Termes',
  'ui.action.register.submitButton.label': 'Créer un compte',
  'ui.action.register.submitButton.loading': 'Chargement',
  'ui.action.register.logIn': 'Se connecter',
  'ui.action.logIn.username.label': 'E-mail',
  'ui.action.logIn.username.placeholder': 'E-mail',
  'ui.action.logIn.username.requiredError': "Entrez l'email",
  'ui.action.logIn.password.label': 'Mot de passe',
  'ui.action.logIn.password.placeholder': 'Mot de passe',
  'ui.action.logIn.password.requiredError': 'Entrez le mot de passe',
  'ui.action.logIn.genericError': 'E-mail ou mot de passe incorrect',
  'ui.action.logIn.forgotenPassword': 'Mot de passe oublié?',
  'ui.action.logIn.submitButton.label': 'Se connecter',
  'ui.action.logIn.submitButton.loading': 'Chargement',
  'ui.action.logIn.register': 'Créer un compte',
  'ui.action.forgottenPassword.requestSent':
    'Un email a maintenant été envoyé avec des instructions sur comment réinitialiser le mot de passe.',
  'ui.action.forgottenPassword.clickToLoginText':
    'Cliquez sur le bouton ci-dessous pour vous reconnecter.',
  'ui.action.forgottenPassword.logIn': 'Se connecter',
  'ui.action.forgottenPassword.email.label': 'E-mail',
  'ui.action.forgottenPassword.email.placeholder': 'E-mail',
  'ui.action.forgottenPassword.email.requiredError': "Entrez l'email",
  'ui.action.forgottenPassword.genericError':
    'Quelque chose a mal tourné, veuillez réessayer plus tard',
  'ui.action.forgottenPassword.submitButton.label':
    'Réinitialiser le mot de passe',
  'ui.action.forgottenPassword.submitButton.loading': 'Chargement',
  'ui.action.forgottenPassword.cancel': 'Annuler',
  'ui.shareModal.action.saveTrip.testInfo':
    'Bonjour! Nous développons cette fonction. Pour le moment, vous pouvez sauvegarder et reprendre votre voyage facilement en cliquant sur le bouton "Copier le lien". Merci de votre compréhension!',
};
