import BookWebComponentType1 from './components/BookWebComponentType1';
import BookWebComponentType2 from './components/BookWebComponentType2';

BookWebComponentType1.register();
BookWebComponentType2.register();

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'book-component-type-1': unknown;
      'book-component-type-2': unknown;
    }
  }
}
