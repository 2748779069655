// NOTE: Only add here if you need translations
//       to be available from the widgets, as the widgets
//       cannot access Sanity CMS for translations!
export const de = {
  'recipe.pax.adult_one': 'Erwachsener',
  'recipe.pax.adult_plural': 'Erwachsene',
  'recipe.pax.child_one': 'Kind',
  'recipe.pax.child_plural': 'Kinder',
  'categories.multiStopText': 'Mehrere Stopps',
  'price.total': 'Ab',
  'price.shortFrom': 'Ab',
  'price.example': 'Preisbeispiel:',
  'price.pricePerPerson': 'Preis pro Person',
  'ui.action.share': 'Teilen',
  'ui.action.book': 'Buchen',
  'ui.action.view': 'Ansehen',
  'ui.action.width': 'Breite',
  'ui.action.style': 'Stil',
  'ui.action.preview': 'Vorschau',
  'ui.action.copy_link': 'Link kopieren',
  'ui.action.copy_code': 'Code kopieren',
  'ui.action.explore_and_publish': 'Auf dem Marktplatz veröffentlichen',
  'ui.notice.copied': 'Kopiert',
  'ui.instruction.paste_body': 'In den Body-Tag Ihrer Seite kopieren',
  'ui.instruction.paste_head': 'In den Head-Tag Ihrer Seite kopieren',
  'ui.label.widget_builder': 'HTML kopieren',
  'day.single': 'Tag',
  'night.single': 'Nacht',
  'night.plural': 'Nächte',
  'ui.action.close': 'Schließen',
  'ui.shareModal.header': 'Teilen & speichern Sie Ihre Reise',
  'ui.shareModal.action.saveTrip.label': 'Die Reise speichern',
  'ui.shareModal.action.saveTrip.saved': 'Ihre Reise wurde gespeichert.',
  'ui.action.logIn.header': 'Melden Sie sich an, um fortzufahren',
  'ui.action.register.header': 'Konto erstellen',
  'ui.action.forgottenPassword.header': 'Passwort zurücksetzen',
  'ui.action.register.firstName.label': 'Vorname',
  'ui.action.register.firstName.placeholder': 'Vorname',
  'ui.action.register.lastName.label': 'Nachname',
  'ui.action.register.lastName.placeholder': 'Nachname',
  'ui.action.register.name.requiredError': 'Name eingeben',
  'ui.action.register.email.label': 'E-Mail',
  'ui.action.register.email.placeholder': 'E-Mail',
  'ui.action.register.email.requiredError': 'E-Mail eingeben',
  'ui.action.register.password.label': 'Passwort',
  'ui.action.register.password.placeholder': 'Passwort',
  'ui.action.register.password.requirements': 'Passwort eingeben',
  'ui.action.register.password.requiredError': 'Passwort eingeben',
  'ui.action.register.password.minLengthError': 'Mindestens 6 Zeichen eingeben',
  'ui.action.register.password.maxLengthError': 'Maximal 50 Zeichen',
  'ui.action.register.confirmPassword.label': 'Passwort bestätigen',
  'ui.action.register.confirmPassword.placeholder': 'Passwort bestätigen',
  'ui.action.register.confirmPassword.requiredError': 'Passwort eingeben',
  'ui.action.register.confirmPassword.noMatchError':
    'Passwörter stimmen nicht überein',
  'ui.action.register.newsletter.info':
    'Ich möchte Newsletter mit Inspiration und Angeboten von Sembo erhalten',
  'ui.action.register.genericError':
    'Etwas ist schief gelaufen, bitte versuchen Sie es später erneut',
  'ui.action.register.termsAndConditions.info':
    'Mit Klick auf „Konto erstellen“ akzeptiere ich',
  'ui.action.register.termsAndConditions.linkLabel': 'Bedingungen',
  'ui.action.register.submitButton.label': 'Konto erstellen',
  'ui.action.register.submitButton.loading': 'Laden',
  'ui.action.register.logIn': 'Einloggen',
  'ui.action.logIn.username.label': 'E-Mail',
  'ui.action.logIn.username.placeholder': 'E-Mail',
  'ui.action.logIn.username.requiredError': 'E-Mail eingeben',
  'ui.action.logIn.password.label': 'Passwort',
  'ui.action.logIn.password.placeholder': 'Passwort',
  'ui.action.logIn.password.requiredError': 'Passwort eingeben',
  'ui.action.logIn.genericError': 'Falsche E-Mail oder Passwort',
  'ui.action.logIn.forgotenPassword': 'Passwort vergessen?',
  'ui.action.logIn.submitButton.label': 'Einloggen',
  'ui.action.logIn.submitButton.loading': 'Laden',
  'ui.action.logIn.register': 'Konto erstellen',
  'ui.action.forgottenPassword.requestSent':
    'Sie haben nun eine E-Mail mit Anweisungen zum Zurücksetzen Ihres Passworts erhalten.',
  'ui.action.forgottenPassword.clickToLoginText':
    'Klicken Sie auf die Schaltfläche unten, um sich erneut anzumelden.',
  'ui.action.forgottenPassword.logIn': 'Einloggen',
  'ui.action.forgottenPassword.email.label': 'E-Mail',
  'ui.action.forgottenPassword.email.placeholder': 'E-Mail',
  'ui.action.forgottenPassword.email.requiredError': 'E-Mail eingeben',
  'ui.action.forgottenPassword.genericError':
    'Etwas ist schief gelaufen, bitte versuchen Sie es später erneut',
  'ui.action.forgottenPassword.submitButton.label': 'Passwort zurücksetzen',
  'ui.action.forgottenPassword.submitButton.loading': 'Laden',
  'ui.action.forgottenPassword.cancel': 'Stornieren',
  'ui.shareModal.action.saveTrip.testInfo':
    'Hallo! Wir entwickeln diese Funktion. Momentan können Sie Ihre Reise einfach speichern und fortsetzen, indem Sie auf die Schaltfläche "Link kopieren" klicken. Vielen Dank für Ihr Verständnis!',
};
