import { SemboMarket } from '../../utils/markets';
import { encodeRecipe } from '../own/recipeHelpers';
import { RecipeQl } from './types';

export const encodeJsonRecipe = (r: string) => r.replace(/'/g, '&apos;');
export const decodeJsonRecipe = (r: string) =>
  r.replace(/&apos;/g, "'").replace(/&quot;/g, '"');

export const formatDate = (dateString: string, lang: string | undefined) => {
  const year = parseInt(dateString.slice(0, 4));
  const month = parseInt(dateString.slice(4, 6)) - 1;
  const day = parseInt(dateString.slice(6, 8));

  const date = new Date(year, month, day);

  return date.toLocaleDateString(lang, {
    month: 'long',
    day: 'numeric',
  });
};

export function buildRecipeWithHotelAndFlight(
  market: SemboMarket,
  hotelCode: string,
  iata: string,
  pax: RecipeQl['pax']
): string {
  return JSON.stringify({
    pax,
    ingredients: [
      {
        transportTypes: ['FLIGHT'],
        ingredientType: 'TRANSPORT',
        fromIata: 'ARN',
        toIata: iata,
        carriers: [],
      },
      {
        hotelCodes: [hotelCode],
        selectedHotelTypes: ['APARTMENT', 'HOTEL', 'HOUSE_OR_COTTAGE'],
        maxNumberOfHotels: 200,
        starRating: 0,
        hasParking: false,
        hasPool: false,
        petsAreAllowed: false,
        freeCancellation: false,
        boardBasis: [],
        ingredientType: 'HOTEL',
        numberOfNights: 7,
        heartedHotels: [],
        subIngredients: [],
      },
      {
        transportTypes: ['FLIGHT'],
        ingredientType: 'TRANSPORT',
        fromIata: iata,
        toIata: 'ARN',
        carriers: [],
      },
    ],
    market: `https://${market.host}`,
    suggestedBy: 'CHEAPEST',
  });
}

export function buildRecipe(
  destination: string | undefined,
  pax: RecipeQl['pax'],
  month?: number,
  nights?: number
): string {
  return JSON.stringify({
    ingredients: [
      {
        ingredientType: 'Hotel',
        numberOfNights: nights,
        destination: destination,
      },
    ],
    pax: pax,
    selectedMonth: month,
  });
}

function isNotLatin1(str: string) {
  for (let i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) > 255) {
      // is not Latin1, so this character cannot be encoded with base64
      // without doing URI encoding first
      return true;
    }
  }
  return false;
}

// We do customEncode so that we don't have to URI encode every character in the recipe,
// reducing the overall string size of the recipe by 20-30%
export function customEncode(
  str: string,
  alwaysEncodeFirstCharacter = true
): string {
  return Array.from(str)
    .map((char, i) =>
      // we encode the first symbol so that TP will realize that this a URI encoded recipe
      isNotLatin1(char) || (alwaysEncodeFirstCharacter && i === 0)
        ? encodeURIComponent(char)
        : char
    )
    .join('');
}

export function buildAndEncodeRecipe(
  destination: string | undefined,
  pax: RecipeQl['pax'],
  month?: number,
  nights?: number
): string {
  const recipeJSON = buildRecipe(destination, pax, month, nights);

  return encodeRecipe(recipeJSON);
}
