import styled from '@emotion/styled';

const Text = styled.span`
  color: #505050;
  text-align: center;
  font-size: 2em;
  font-weight: 900;
  margin-top: 2.5%;
  padding: 2%;
`;
export default Text;
