import styled from '@emotion/styled';

const CardTitle = styled.h5`
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  font: var(--font-heading-5);
  color: var(--color-secondary-40);
  height: 3rem;
  margin: 0;
`;
export default CardTitle;
