import styled from '@emotion/styled';

const ComponentContainer = styled.div`
  height: auto;
  width: 100%;
  max-width: 350px;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
`;
export default ComponentContainer;
