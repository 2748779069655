// NOTE: Only add here if you need translations
//       to be available from the widgets, as the widgets
//       cannot access Sanity CMS for translations!
export const sv = {
  'recipe.pax.adult_one': 'vuxen',
  'recipe.pax.adult_plural': 'vuxna',
  'recipe.pax.child_one': 'barn',
  'recipe.pax.child_plural': 'barn',
  'categories.multiStopText': 'Flera stopp',
  'price.total': 'Från',
  'price.shortFrom': 'Fr.',
  'price.example': 'Prisexempel:',
  'price.pricePerPerson': 'Pris per person',
  'ui.action.share': 'Dela',
  'ui.action.book': 'Boka',
  'ui.action.view': 'Visa',
  'ui.action.width': 'Bredd',
  'ui.action.style': 'Stil',
  'ui.action.preview': 'Förhandsgranska',
  'ui.action.copy_link': 'Kopiera länk',
  'ui.action.copy_code': 'Kopiera html',
  'ui.action.explore_and_publish': 'Publicera till Marketplace',
  'ui.notice.copied': 'Kopierad',
  'ui.instruction.paste_body': 'Kopiera till din sidas body tag',
  'ui.instruction.paste_head': 'Kopiera till din sidas head tag',
  'ui.label.widget_builder': 'Widget-byggare',
  'day.single': 'dag',
  'night.single': 'natt',
  'night.plural': 'nätter',
  'ui.action.close': 'Stäng',
  'ui.shareModal.header': 'Dela & spara din resa',
  'ui.shareModal.action.saveTrip.label': 'Spara resan',
  'ui.shareModal.action.saveTrip.saved': 'Din resa sparades.',
  'ui.action.logIn.header': 'Logga in för att fortsätta',
  'ui.action.register.header': 'Skapa konto',
  'ui.action.forgottenPassword.header': 'Återställ lösenord',
  'ui.action.register.firstName.label': 'Förnamn',
  'ui.action.register.firstName.placeholder': 'Förnamn',
  'ui.action.register.lastName.label': 'Efternamn',
  'ui.action.register.lastName.placeholder': 'Efternamn',
  'ui.action.register.name.requiredError': 'Ange namn',
  'ui.action.register.email.label': 'E-postadress',
  'ui.action.register.email.placeholder': 'E-postadress',
  'ui.action.register.email.requiredError': 'Ange e-postadress',
  'ui.action.register.password.label': 'Lösenord',
  'ui.action.register.password.placeholder': 'Lösenord',
  'ui.action.register.password.requirements': 'Ange lösenord',
  'ui.action.register.password.requiredError': 'Ange lösenord',
  'ui.action.register.password.minLengthError': 'Ange minst 6 tecken',
  'ui.action.register.password.maxLengthError': 'Max 50 tecken',
  'ui.action.register.confirmPassword.label': 'Bekräfta lösenord',
  'ui.action.register.confirmPassword.placeholder': 'Bekräfta lösenord',
  'ui.action.register.confirmPassword.requiredError': 'Ange lösenord',
  'ui.action.register.confirmPassword.noMatchError': 'Lösenorden matchar inte',
  'ui.action.register.newsletter.info':
    'Jag vill få nyhetsbrev med inspiration och erbjudande från Sembo',
  'ui.action.register.genericError': 'Något gick fel, försök igen senare',
  'ui.action.register.termsAndConditions.info':
    'Genom att välja "Registrera dig" nedan godkänner jag',
  'ui.action.register.termsAndConditions.linkLabel': 'Villkor',
  'ui.action.register.submitButton.label': 'Skapa konto',
  'ui.action.register.submitButton.loading': 'Laddar',
  'ui.action.register.logIn': 'Logga in',
  'ui.action.logIn.username.label': 'E-postadress',
  'ui.action.logIn.username.placeholder': 'E-postadress',
  'ui.action.logIn.username.requiredError': 'Ange e-postadress',
  'ui.action.logIn.password.label': 'Lösenord',
  'ui.action.logIn.password.placeholder': 'Lösenord',
  'ui.action.logIn.password.requiredError': 'Ange lösenord',
  'ui.action.logIn.genericError': 'Felaktig e-post eller lösenord',
  'ui.action.logIn.forgotenPassword': 'Glömt lösenord?',
  'ui.action.logIn.submitButton.label': 'Logga in',
  'ui.action.logIn.submitButton.loading': 'Laddar',
  'ui.action.logIn.register': 'Skapa konto',
  'ui.action.forgottenPassword.requestSent':
    'Ett email har nu skickats med instruktioner om hur du återställer lösenordet.',
  'ui.action.forgottenPassword.clickToLoginText':
    'Klicka på knappen nedan för att logga in igen.',
  'ui.action.forgottenPassword.logIn': 'Logga in',
  'ui.action.forgottenPassword.email.label': 'E-postadress',
  'ui.action.forgottenPassword.email.placeholder': 'E-postadress',
  'ui.action.forgottenPassword.email.requiredError': 'Ange e-postadress',
  'ui.action.forgottenPassword.genericError':
    'Något gick fel, försök igen senare',
  'ui.action.forgottenPassword.submitButton.label': 'Återställ lösenord',
  'ui.action.forgottenPassword.submitButton.loading': 'Laddar',
  'ui.action.forgottenPassword.cancel': 'Avbryt',
  'ui.shareModal.action.saveTrip.testInfo':
    'Hej! Vi håller på att utveckla denna funktion. För tillfället kan du spara och återuppta din resa enkelt genom att klicka på "Kopiera länk"-knappen. Tack för din förståelse!',
};
