// NOTE: Only add here if you need translations
//       to be available from the widgets, as the widgets
//       cannot access Sanity CMS for translations!
export const es = {
  'recipe.pax.adult_one': 'adulto',
  'recipe.pax.adult_plural': 'adultos',
  'recipe.pax.child_one': 'niño',
  'recipe.pax.child_plural': 'niños',
  'categories.multiStopText': 'Múltiples paradas',
  'price.total': 'Desde',
  'price.shortFrom': 'Desde',
  'price.example': 'Ejemplo de precio:',
  'price.pricePerPerson': 'Precio por persona',
  'ui.action.share': 'Compartir',
  'ui.action.book': 'Reservar',
  'ui.action.view': 'Ver',
  'ui.action.width': 'Ancho',
  'ui.action.style': 'Estilo',
  'ui.action.preview': 'Vista previa',
  'ui.action.copy_link': 'Copiar enlace',
  'ui.action.copy_code': 'Copiar código',
  'ui.action.explore_and_publish': 'Publicar en el Marketplace',
  'ui.notice.copied': 'Copiado',
  'ui.instruction.paste_body': 'Copia esto en la etiqueta body de tu página',
  'ui.instruction.paste_head': 'Copia esto en la etiqueta head de tu página',
  'ui.label.widget_builder': 'Copiar HTML',
  'day.single': 'día',
  'night.single': 'noche',
  'night.plural': 'noches',
  'ui.action.close': 'Cerrar',
  'ui.shareModal.header': 'Compartir y guardar tu viaje',
  'ui.shareModal.action.saveTrip.label': 'Guardar el viaje',
  'ui.shareModal.action.saveTrip.saved': 'Tu viaje ha sido guardado.',
  'ui.action.logIn.header': 'Inicia sesión para continuar',
  'ui.action.register.header': 'Crear cuenta',
  'ui.action.forgottenPassword.header': 'Restablecer contraseña',
  'ui.action.register.firstName.label': 'Nombre de pila',
  'ui.action.register.firstName.placeholder': 'Nombre',
  'ui.action.register.lastName.label': 'Apellido',
  'ui.action.register.lastName.placeholder': 'Apellido',
  'ui.action.register.name.requiredError': 'Ingrese nombre',
  'ui.action.register.email.label': 'Correo electrónico',
  'ui.action.register.email.placeholder': 'Correo electrónico',
  'ui.action.register.email.requiredError': 'Ingrese correo electrónico',
  'ui.action.register.password.label': 'Contraseña',
  'ui.action.register.password.placeholder': 'Contraseña',
  'ui.action.register.password.requirements': 'Ingrese contraseña',
  'ui.action.register.password.requiredError': 'Ingrese contraseña',
  'ui.action.register.password.minLengthError': 'Ingrese al menos 6 caracteres',
  'ui.action.register.password.maxLengthError': 'Máximo 50 caracteres',
  'ui.action.register.confirmPassword.label': 'Confirmar contraseña',
  'ui.action.register.confirmPassword.placeholder': 'Confirmar contraseña',
  'ui.action.register.confirmPassword.requiredError': 'Ingrese contraseña',
  'ui.action.register.confirmPassword.noMatchError':
    'Las contraseñas no coinciden',
  'ui.action.register.newsletter.info':
    'Quiero recibir boletines con inspiración y ofertas de Sembo',
  'ui.action.register.genericError':
    'Algo salió mal, por favor intente de nuevo más tarde',
  'ui.action.register.termsAndConditions.info':
    'Al hacer clic en "Crear cuenta" acepto',
  'ui.action.register.termsAndConditions.linkLabel': 'Términos',
  'ui.action.register.submitButton.label': 'Crear cuenta',
  'ui.action.register.submitButton.loading': 'Cargando',
  'ui.action.register.logIn': 'Iniciar sesión',
  'ui.action.logIn.username.label': 'Correo electrónico',
  'ui.action.logIn.username.placeholder': 'Correo electrónico',
  'ui.action.logIn.username.requiredError': 'Ingrese correo electrónico',
  'ui.action.logIn.password.label': 'Contraseña',
  'ui.action.logIn.password.placeholder': 'Contraseña',
  'ui.action.logIn.password.requiredError': 'Ingrese contraseña',
  'ui.action.logIn.genericError': 'Correo electrónico o contraseña incorrectos',
  'ui.action.logIn.forgotenPassword': '¿Olvidaste tu contraseña?',
  'ui.action.logIn.submitButton.label': 'Iniciar sesión',
  'ui.action.logIn.submitButton.loading': 'Cargando',
  'ui.action.logIn.register': 'Crear cuenta',
  'ui.action.forgottenPassword.requestSent':
    'Ahora se ha enviado un correo electrónico con instrucciones sobre cómo restablecer la contraseña.',
  'ui.action.forgottenPassword.clickToLoginText':
    'Haga clic en el botón de abajo para iniciar sesión de nuevo.',
  'ui.action.forgottenPassword.logIn': 'Iniciar sesión',
  'ui.action.forgottenPassword.email.label': 'Correo electrónico',
  'ui.action.forgottenPassword.email.placeholder': 'Correo electrónico',
  'ui.action.forgottenPassword.email.requiredError':
    'Ingrese correo electrónico',
  'ui.action.forgottenPassword.genericError':
    'Algo salió mal, por favor intente de nuevo más tarde',
  'ui.action.forgottenPassword.submitButton.label': 'Restablecer contraseña',
  'ui.action.forgottenPassword.submitButton.loading': 'Cargando',
  'ui.action.forgottenPassword.cancel': 'Cancelar',
  'ui.shareModal.action.saveTrip.testInfo':
    '¡Hola! Estamos desarrollando esta función. Por el momento, puedes guardar y reanudar tu viaje fácilmente haciendo clic en el botón "Copiar enlace". ¡Gracias por tu comprensión!',
};
