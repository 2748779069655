import styled from '@emotion/styled';

type PreviewsPropType = { quantity: number };

const Previews = styled.div<PreviewsPropType>`
  display: grid;
  position: relative;
  grid-gap: 2%;
  grid-template-columns: repeat(
    ${(props) => (props.quantity > 1 ? 4 : 1)},
    1fr
  );
  > * {
    &:nth-last-of-type(2):nth-of-type(odd) {
      grid-column: 2 / span 2 !important;
    }
  }
`;
export default Previews;
