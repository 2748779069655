// NOTE: Only add here if you need translations
//       to be available from the widgets, as the widgets
//       cannot access Sanity CMS for translations!
export const en = {
  'recipe.pax.adult_one': 'adult',
  'recipe.pax.adult_plural': 'adults',
  'recipe.pax.child_one': 'child',
  'recipe.pax.child_plural': 'children',
  'categories.multiStopText': 'Multi-stop',
  'price.total': 'From',
  'price.shortFrom': 'From',
  'price.example': 'Price example:',
  'price.pricePerPerson': 'Price per person',
  'ui.action.share': 'Share',
  'ui.action.book': 'Book',
  'ui.action.view': 'View',
  'ui.action.width': 'Width',
  'ui.action.style': 'Style',
  'ui.action.preview': 'Preview',
  'ui.action.copy_link': 'Copy link',
  'ui.action.copy_code': 'Copy code',
  'ui.action.explore_and_publish': 'Publish to Marketplace',
  'ui.notice.copied': 'Copied',
  'ui.instruction.paste_body': "Copy to your page's body tag",
  'ui.instruction.paste_head': "Copy to your page's head tag",
  'ui.label.widget_builder': 'Copy html',
  'day.single': 'day',
  'night.single': 'night',
  'night.plural': 'nights',
  'ui.action.close': 'Close',
  'ui.shareModal.header': 'Share & save your trip',
  'ui.shareModal.action.saveTrip.label': 'Save trip',
  'ui.shareModal.action.saveTrip.saved': 'Your trip was saved.',
  'ui.action.logIn.header': 'Sign in to continue',
  'ui.action.register.header': 'Create account',
  'ui.action.forgottenPassword.header': 'Reset password',
  'ui.action.register.firstName.label': 'First name',
  'ui.action.register.firstName.placeholder': 'First name',
  'ui.action.register.lastName.label': 'Last name',
  'ui.action.register.lastName.placeholder': 'Last name',
  'ui.action.register.name.requiredError': 'Enter name',
  'ui.action.register.email.label': 'E-mail',
  'ui.action.register.email.placeholder': 'E-mail',
  'ui.action.register.email.requiredError': 'Enter e-mail',
  'ui.action.register.password.label': 'Password',
  'ui.action.register.password.placeholder': 'Password',
  'ui.action.register.password.requirements': 'Enter password',
  'ui.action.register.password.requiredError': 'Enter password',
  'ui.action.register.password.minLengthError': 'Enter at least 6 characters',
  'ui.action.register.password.maxLengthError': 'Maximum 50 characters',
  'ui.action.register.confirmPassword.label': 'Confirm password',
  'ui.action.register.confirmPassword.placeholder': 'Confirm password',
  'ui.action.register.confirmPassword.requiredError': 'Enter password',
  'ui.action.register.confirmPassword.noMatchError':
    'The passwords do not match',
  'ui.action.register.newsletter.info':
    'I want to receive newsletters with inspiration and offers from Sembo',
  'ui.action.register.genericError':
    'Something went wrong, please try again later',
  'ui.action.register.termsAndConditions.info':
    'By clicking "Create account" I accept',
  'ui.action.register.termsAndConditions.linkLabel': 'Terms',
  'ui.action.register.submitButton.label': 'Create account',
  'ui.action.register.submitButton.loading': 'Loading',
  'ui.action.register.logIn': 'Log in',
  'ui.action.logIn.username.label': 'E-mail',
  'ui.action.logIn.username.placeholder': 'E-mail',
  'ui.action.logIn.username.requiredError': 'Enter e-mail',
  'ui.action.logIn.password.label': 'Password',
  'ui.action.logIn.password.placeholder': 'Password',
  'ui.action.logIn.password.requiredError': 'Enter password',
  'ui.action.logIn.genericError': 'Incorrect e-mail or password',
  'ui.action.logIn.forgotenPassword': 'Forgot password?',
  'ui.action.logIn.submitButton.label': 'Log in',
  'ui.action.logIn.submitButton.loading': 'Loading',
  'ui.action.logIn.register': 'Create account',
  'ui.action.forgottenPassword.requestSent':
    'An e-mail has now been sent with instructions on how to reset the password.',
  'ui.action.forgottenPassword.clickToLoginText':
    'Click the button below to sign in again.',
  'ui.action.forgottenPassword.logIn': 'Log in',
  'ui.action.forgottenPassword.email.label': 'E-mail',
  'ui.action.forgottenPassword.email.placeholder': 'E-mail',
  'ui.action.forgottenPassword.email.requiredError': 'Enter e-mail',
  'ui.action.forgottenPassword.genericError':
    'Something went wrong, please try again later',
  'ui.action.forgottenPassword.submitButton.label': 'Reset password',
  'ui.action.forgottenPassword.submitButton.loading': 'Loading',
  'ui.action.forgottenPassword.cancel': 'Cancel',
  'ui.shareModal.action.saveTrip.testInfo':
    'Hello! We are developing this function. At the moment, you can save and resume your trip easily by clicking the "Copy link" button. Thank you for your understanding!',
};
