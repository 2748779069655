// eslint-disable-next-line no-restricted-imports
import { createGlobalStyle } from 'styled-components';

const GlobalColors = createGlobalStyle`
:root {
  --white:#fff;
  --black:#000;
  --light-gray:#d3d3d3;
  --dark-0:#2E3440;
  --dark-1:#3B4252;
  --dark-2:#434C5E;
  --dark-3: #4C566A;
  --snow-0:#D8DEE9;
  --snow-1:#E5E9F0;
  --snow-2:#ECEFF4;
  --blue-green-1:#5E81AC;
  --blue-green-2:#81A1C1;
  --blue-green-3:#88C0D0;
  --blue-green-4:#8FBCBB;
  --common-1:#BF616A;
  --common-2:#D08770;
  --common-3:#EBCB8B;
  --common-4:#A3BE8C;
  --common-5:#B48EAD;
}`;
export default GlobalColors;
