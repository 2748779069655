import styled from '@emotion/styled';

const DescriptionText = styled.p`
  font: var(--font-body-m-light);
  color: var(--color-secondary-40);
  margin: var(--spacing-8) 0 0;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export default DescriptionText;
