import Cookie from 'universal-cookie';

class Affiliate {
  private cookie: Cookie;

  constructor() {
    this.cookie = new Cookie();
  }

  public getAffiliateId(): string | null {
    const cookieValue = this.cookie.get('AffiliateCookie');

    if (cookieValue) {
      const idMatch = cookieValue.match(/AffiliateId=(.*?)&/);

      if (idMatch?.[1]) {
        return idMatch[1];
      }
    }

    return null;
  }
  public setAffiliateId(id: string): void {
    this.cookie.set('AffiliateCookie', `AffiliateId=${id}`, {
      encode: (value: string) => value,
    } as any);
  }

  public removeAffiliateId(): void {
    this.cookie.remove('AffiliateCookie');
  }
}

export const AffiliateService = new Affiliate();
