import BookComponentType2 from '../../../../apps/explore/widgets/book-component-type-2/BookComponentType2';
import { WebComponent } from './WebComponent';

const name = 'book-component-type-2';

class BookWebComponent extends WebComponent {
  constructor() {
    super(name, BookComponentType2);
  }

  static register() {
    window.customElements.get(name) ||
      window.customElements.define(name, BookWebComponent);
  }
}
export default BookWebComponent;
