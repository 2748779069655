export class WidgetError extends Error {
  public readonly targetUrl: string;
  public readonly title: string;
  public readonly recipe: string;
  public readonly innerError?: Error;

  constructor(
    message: string,
    targetUrl = '',
    title = '',
    recipe = '',
    innerError?: Error
  ) {
    super(message);
    this.targetUrl = targetUrl;
    this.title = title;
    this.recipe = recipe;
    this.innerError = innerError;
  }
}
