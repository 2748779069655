// NOTE: Only add here if you need translations
//       to be available from the widgets, as the widgets
//       cannot access Sanity CMS for translations!
export const da = {
  'recipe.pax.adult_one': 'voksen',
  'recipe.pax.adult_plural': 'voksne',
  'recipe.pax.child_one': 'barn',
  'recipe.pax.child_plural': 'børn',
  'categories.multiStopText': 'Flere stop',
  'price.total': 'Fra',
  'price.shortFrom': 'Fra',
  'price.example': 'Priseksempel:',
  'price.pricePerPerson': 'Pris per person',
  'ui.action.share': 'Del',
  'ui.action.book': 'Book nu',
  'ui.action.view': 'Se',
  'ui.action.width': 'Bredde',
  'ui.action.style': 'Stil',
  'ui.action.preview': 'Forhåndsvisning',
  'ui.action.copy_link': 'Kopier link',
  'ui.action.copy_code': 'Kopier kode',
  'ui.action.explore_and_publish': 'Publicer på Markedspladsen',
  'ui.notice.copied': 'Kopieret',
  'ui.instruction.paste_body': 'Kopier til din sides body-tag',
  'ui.instruction.paste_head': 'Kopier til din sides head-tag',
  'ui.label.widget_builder': 'Kopier HTML',
  'day.single': 'Dag',
  'night.single': 'Nat',
  'night.plural': 'Nætter',
  'ui.action.close': 'Luk',
  'ui.shareModal.header': 'Del & gem din tur',
  'ui.shareModal.action.saveTrip.label': 'Gem turen',
  'ui.shareModal.action.saveTrip.saved': 'Din tur blev gemt.',
  'ui.action.logIn.header': 'Log ind for at fortsætte',
  'ui.action.register.header': 'Opret konto',
  'ui.action.forgottenPassword.header': 'Nulstil adgangskode',
  'ui.action.register.firstName.label': 'Fornavn',
  'ui.action.register.firstName.placeholder': 'Fornavn',
  'ui.action.register.lastName.label': 'Efternavn',
  'ui.action.register.lastName.placeholder': 'Efternavn',
  'ui.action.register.name.requiredError': 'Indtast navn',
  'ui.action.register.email.label': 'E-mail',
  'ui.action.register.email.placeholder': 'E-mail',
  'ui.action.register.email.requiredError': 'Indtast e-mail',
  'ui.action.register.password.label': 'Adgangskode',
  'ui.action.register.password.placeholder': 'Adgangskode',
  'ui.action.register.password.requirements': 'Indtast adgangskode',
  'ui.action.register.password.requiredError': 'Indtast adgangskode',
  'ui.action.register.password.minLengthError': 'Indtast mindst 6 tegn',
  'ui.action.register.password.maxLengthError': 'Maks. 50 tegn',
  'ui.action.register.confirmPassword.label': 'Bekræft adgangskode',
  'ui.action.register.confirmPassword.placeholder': 'Bekræft adgangskode',
  'ui.action.register.confirmPassword.requiredError': 'Indtast adgangskode',
  'ui.action.register.confirmPassword.noMatchError':
    'Adgangskoderne matcher ikke',
  'ui.action.register.newsletter.info':
    'Jeg vil modtage nyhedsbreve med inspiration og tilbud fra Sembo',
  'ui.action.register.genericError': 'Noget gik galt, prøv igen senere',
  'ui.action.register.termsAndConditions.info':
    'Ved at vælge "Registrer dig" nedenfor accepterer jeg',
  'ui.action.register.termsAndConditions.linkLabel': 'Betingelser',
  'ui.action.register.submitButton.label': 'Opret konto',
  'ui.action.register.submitButton.loading': 'Indlæser',
  'ui.action.register.logIn': 'Log ind',
  'ui.action.logIn.username.label': 'E-mail',
  'ui.action.logIn.username.placeholder': 'E-mail',
  'ui.action.logIn.username.requiredError': 'Indtast e-mail',
  'ui.action.logIn.password.label': 'Adgangskode',
  'ui.action.logIn.password.placeholder': 'Adgangskode',
  'ui.action.logIn.password.requiredError': 'Indtast adgangskode',
  'ui.action.logIn.genericError': 'Forkert e-mail eller adgangskode',
  'ui.action.logIn.forgotenPassword': 'Glemt adgangskode?',
  'ui.action.logIn.submitButton.label': 'Log ind',
  'ui.action.logIn.submitButton.loading': 'Indlæser',
  'ui.action.logIn.register': 'Opret konto',
  'ui.action.forgottenPassword.requestSent':
    'Der er nu sendt en e-mail med instruktioner om, hvordan du nulstiller din adgangskode.',
  'ui.action.forgottenPassword.clickToLoginText':
    'Klik på knappen nedenfor for at logge ind igen.',
  'ui.action.forgottenPassword.logIn': 'Log ind',
  'ui.action.forgottenPassword.email.label': 'E-mail',
  'ui.action.forgottenPassword.email.placeholder': 'E-mail',
  'ui.action.forgottenPassword.email.requiredError': 'Indtast e-mail',
  'ui.action.forgottenPassword.genericError':
    'Noget gik galt, prøv igen senere',
  'ui.action.forgottenPassword.submitButton.label': 'Nulstil adgangskode',
  'ui.action.forgottenPassword.submitButton.loading': 'Indlæser',
  'ui.action.forgottenPassword.cancel': 'Annuller',
  'ui.shareModal.action.saveTrip.testInfo':
    'Hej! Vi er ved at udvikle denne funktion. I øjeblikket kan du gemme og genoptage din rejse nemt ved at klikke på "Kopier link"-knappen. Tak for din forståelse!',
};
