import styled from '@emotion/styled';

const ProductCardContainer = styled.div`
  width: 350px;
  height: 450px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  position: relative;
  align-items: center;
`;
export default ProductCardContainer;
