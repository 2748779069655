import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The `Date` scalar type represents a year, month and day in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  Date: { input: any; output: any };
  /** The `Date` scalar type represents a year, month and day in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. Format is `yyyy-MM-dd` */
  DateOnly: { input: any; output: any };
  /** The `DateTime` scalar type represents a date and time. `DateTime` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTime: { input: Date; output: Date };
  Decimal: { input: any; output: any };
  Uri: { input: any; output: any };
};

export type AiThreadResponseQl = {
  __typename?: 'AIThreadResponseQL';
  additionalTexts?: Maybe<AdditionalTextQl>;
  messageId?: Maybe<Scalars['String']['output']>;
  recipeJson?: Maybe<Scalars['String']['output']>;
  replyToUser?: Maybe<Scalars['String']['output']>;
  sessionId?: Maybe<Scalars['String']['output']>;
  threadId?: Maybe<Scalars['String']['output']>;
};

export type ActivityQl = {
  code: Scalars['String']['input'];
  dateOnly?: InputMaybe<Scalars['DateOnly']['input']>;
  tickets?: InputMaybe<Array<InputMaybe<SelectedTicketQl>>>;
};

export type AdditionalTextQl = {
  __typename?: 'AdditionalTextQL';
  destinations?: Maybe<Array<Maybe<DestinationAdditionalTextQl>>>;
  id?: Maybe<Scalars['String']['output']>;
  imageUrl: Scalars['String']['output'];
  inspirationalMessage?: Maybe<Scalars['String']['output']>;
};

export type AgeRangeQl = {
  __typename?: 'AgeRangeQL';
  availableTimeslotsPrices?: Maybe<
    Array<Maybe<AvailableTimeslotsPricesProjectedQl>>
  >;
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  maxAge?: Maybe<Scalars['Int']['output']>;
  minAge: Scalars['Int']['output'];
  minimumTicketsPerAgeRange?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<MoneyQl>;
  quantityAvailable?: Maybe<Scalars['Int']['output']>;
};

export type AvailableTimeslotsPricesProjectedQl = {
  __typename?: 'AvailableTimeslotsPricesProjectedQL';
  amount: Scalars['Decimal']['output'];
  availabletimeSlotId: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  currencySymbol: Scalars['String']['output'];
  quantityAvailable?: Maybe<Scalars['Int']['output']>;
};

export type BookingTokenRequestQl = {
  activities?: InputMaybe<Array<InputMaybe<ActivityQl>>>;
  affiliateId?: InputMaybe<Scalars['String']['input']>;
  market: Scalars['Uri']['input'];
};

export type CancellationPolicyResponseQl = {
  __typename?: 'CancellationPolicyResponseQL';
  amount?: Maybe<MoneyQl>;
  fromDate: Scalars['String']['output'];
  fromDateIso: Scalars['String']['output'];
};

export type CheapestHotelStayRequestQl = {
  day?: InputMaybe<Scalars['DateOnly']['input']>;
  hotelCode: Scalars['String']['input'];
  ingredientIndex: Scalars['Int']['input'];
  recipe: RecipeQl;
};

export enum CheapestItineraryStatusType {
  Failure = 'Failure',
  Success = 'Success',
  Unknown = 'Unknown',
}

export type CheapestPriceQl = {
  __typename?: 'CheapestPriceQL';
  boardBasis: Scalars['String']['output'];
  date: Scalars['DateTime']['output'];
  isRefundable: Scalars['Boolean']['output'];
  placeCode: Scalars['String']['output'];
  roomStays?: Maybe<Array<Maybe<RoomStayResponseQl>>>;
  totalPrice?: Maybe<MoneyQl>;
};

export type CheapestStayByHotelRequestQl = {
  constraint: ConstraintQl;
  day?: InputMaybe<Scalars['DateOnly']['input']>;
  ingredientIndex: Scalars['Int']['input'];
  recipe: RecipeQl;
};

export type ConstraintQl = {
  destination?: InputMaybe<Scalars['String']['input']>;
  destinationCode?: InputMaybe<Scalars['Int']['input']>;
  hotelCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  polygon?: InputMaybe<Array<InputMaybe<CoordinateQl>>>;
  polygonId?: InputMaybe<Scalars['Int']['input']>;
  serializedConstraint?: InputMaybe<Scalars['String']['input']>;
  tagId?: InputMaybe<Scalars['Int']['input']>;
};

export type CoordinateQl = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type DayTicketRateQl = {
  __typename?: 'DayTicketRateQL';
  date?: Maybe<Scalars['DateTime']['output']>;
  rates?: Maybe<Array<Maybe<TicketRateQl>>>;
};

export type DecimalRangeTypeQl = {
  from?: InputMaybe<Scalars['Decimal']['input']>;
  to?: InputMaybe<Scalars['Decimal']['input']>;
};

export type DestinationAdditionalTextQl = {
  __typename?: 'DestinationAdditionalTextQL';
  activityTexts?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name?: Maybe<Scalars['String']['output']>;
};

export type DoubleRangeQl = {
  __typename?: 'DoubleRangeQL';
  from?: Maybe<Scalars['Float']['output']>;
  to?: Maybe<Scalars['Float']['output']>;
};

export type DynamicDatePreferencesQl = {
  daysFromNow?: InputMaybe<Scalars['Int']['input']>;
  minimumDaysFromNow?: InputMaybe<Scalars['Int']['input']>;
  minimumDaysLeftInMonth?: InputMaybe<Scalars['Int']['input']>;
  mode: InputDynamicDatePreferencesModeTypeQl;
  monthsFromNow?: InputMaybe<Scalars['Int']['input']>;
  preferredMonths?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type FerryIngredientResponseQl = IngredientResponseQl & {
  __typename?: 'FerryIngredientResponseQL';
  addToBasketToken?: Maybe<Scalars['String']['output']>;
  allCarriers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  carriers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  date?: Maybe<Scalars['Date']['output']>;
  destination?: Maybe<Scalars['String']['output']>;
  destinationTranslation?: Maybe<Scalars['String']['output']>;
  durationRangeInMinutes?: Maybe<DoubleRangeQl>;
  ferryNumbers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  key?: Maybe<Scalars['String']['output']>;
  legDescription?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  maxNumberOfStops?: Maybe<Scalars['Int']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  originTranslation?: Maybe<Scalars['String']['output']>;
  outBoundArrivalTime?: Maybe<Scalars['DateTime']['output']>;
  outBoundDepartureTime?: Maybe<Scalars['DateTime']['output']>;
  price?: Maybe<MoneyQl>;
  totalPrice?: Maybe<MoneyQl>;
};

export type FlightIngredientResponseQl = IngredientResponseQl & {
  __typename?: 'FlightIngredientResponseQL';
  addToBasketToken?: Maybe<Scalars['String']['output']>;
  allCarriers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  carriers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  date?: Maybe<Scalars['DateTime']['output']>;
  destination?: Maybe<Scalars['String']['output']>;
  destinationTranslation?: Maybe<Scalars['String']['output']>;
  durationRangeInMinutes?: Maybe<DoubleRangeQl>;
  flightNumbers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  key?: Maybe<Scalars['String']['output']>;
  legDescription?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  maxNumberOfStops?: Maybe<Scalars['Int']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  originTranslation?: Maybe<Scalars['String']['output']>;
  outBoundArrivalTime?: Maybe<Scalars['DateTime']['output']>;
  outBoundDepartureTime?: Maybe<Scalars['DateTime']['output']>;
  price?: Maybe<MoneyQl>;
  totalPrice?: Maybe<MoneyQl>;
};

export type GetCheapestItineraryResponseQl = {
  __typename?: 'GetCheapestItineraryResponseQL';
  failureReason?: Maybe<Scalars['String']['output']>;
  itinerary?: Maybe<ItineraryResponseQl>;
  status: CheapestItineraryStatusType;
};

export type GetSuggestedAvailabilityRequestQl = {
  constraint: ConstraintQl;
  day: Scalars['DateOnly']['input'];
  ingredientIndex: Scalars['Int']['input'];
  numberOfSuggestions: Scalars['Int']['input'];
  recipe: RecipeQl;
  suggestedBy: InputAvailibityOrderQl;
};

export type GetSuggestedAvailabilityResponseQl = {
  __typename?: 'GetSuggestedAvailabilityResponseQL';
  results?: Maybe<Array<SuggestedAvailabilityResultQl>>;
};

export type HeartedHotelQl = {
  code: Scalars['String']['input'];
  isRefundable?: InputMaybe<Scalars['Boolean']['input']>;
  roomCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rooms?: InputMaybe<Array<InputMaybe<HeartedRoomQl>>>;
};

export type HeartedRoomQl = {
  boardBasis: Scalars['String']['input'];
  code?: InputMaybe<Scalars['String']['input']>;
  roomTypeId?: InputMaybe<Scalars['Int']['input']>;
};

export type HotelIngredientResponseQl = IngredientResponseQl & {
  __typename?: 'HotelIngredientResponseQL';
  addToBasketToken?: Maybe<Scalars['String']['output']>;
  alternativeSuggestion?: Maybe<HotelStayResponseQl>;
  date?: Maybe<Scalars['DateTime']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  lengthOfStay?: Maybe<LengthOfStayQl>;
  numberOfNights?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<MoneyQl>;
  subIngredientResults?: Maybe<Array<Maybe<IngredientResponseQl>>>;
  suggested?: Maybe<HotelStayResponseQl>;
  suggestions?: Maybe<Array<HotelStayResponseQl>>;
  totalPrice?: Maybe<MoneyQl>;
};

export type HotelStayResponseQl = {
  __typename?: 'HotelStayResponseQL';
  addToBasketToken: Scalars['String']['output'];
  date: Scalars['DateOnly']['output'];
  hotelCode: Scalars['String']['output'];
  isRefundable: Scalars['Boolean']['output'];
  prices?: Maybe<PriceQl>;
  roomStays?: Maybe<Array<Maybe<RoomStayResponseQl>>>;
  score?: Maybe<Scalars['Int']['output']>;
  totalPrice?: Maybe<MoneyQl>;
};

export type ImageResponseQl = {
  __typename?: 'ImageResponseQL';
  imageName: Scalars['String']['output'];
  imageService: Scalars['String']['output'];
};

export type IngredientQl = {
  arrivalTimeFilters?: InputMaybe<StringRangeTypeQl>;
  boardBasis?: InputMaybe<Array<InputMaybe<InputBoardBasisQl>>>;
  carriers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  code?: InputMaybe<Scalars['String']['input']>;
  departureTimeFilters?: InputMaybe<StringRangeTypeQl>;
  destination?: InputMaybe<Scalars['String']['input']>;
  destinationCode?: InputMaybe<Scalars['Int']['input']>;
  flightNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  freeCancellation?: InputMaybe<Scalars['Boolean']['input']>;
  fromIata?: InputMaybe<Scalars['String']['input']>;
  hasAirConditioning?: InputMaybe<Scalars['Boolean']['input']>;
  hasElectricVehicleChargingStation?: InputMaybe<Scalars['Boolean']['input']>;
  hasGym?: InputMaybe<Scalars['Boolean']['input']>;
  hasInternet?: InputMaybe<Scalars['Boolean']['input']>;
  hasParking?: InputMaybe<Scalars['Boolean']['input']>;
  hasPool?: InputMaybe<Scalars['Boolean']['input']>;
  hasSpa?: InputMaybe<Scalars['Boolean']['input']>;
  heartedHotels?: InputMaybe<Array<InputMaybe<HeartedHotelQl>>>;
  hotelCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hotelName?: InputMaybe<Scalars['String']['input']>;
  includeAlternativeResults?: InputMaybe<Scalars['Boolean']['input']>;
  ingredientType?: InputMaybe<InputIngredientTypeQl>;
  isBusinessProperty?: InputMaybe<Scalars['Boolean']['input']>;
  isFamilyProperty?: InputMaybe<Scalars['Boolean']['input']>;
  maxDurationInHours?: InputMaybe<Scalars['Float']['input']>;
  maxLayoverTimeInMinutes?: InputMaybe<Scalars['Int']['input']>;
  maxNumberOfHotels?: InputMaybe<Scalars['Int']['input']>;
  maxNumberOfStops?: InputMaybe<Scalars['Int']['input']>;
  numberOfNights?: InputMaybe<Scalars['Int']['input']>;
  numberOfSuggestions?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  petsAreAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  polygon?: InputMaybe<Array<InputMaybe<CoordinateQl>>>;
  polygonId?: InputMaybe<Scalars['Int']['input']>;
  priceFilters?: InputMaybe<DecimalRangeTypeQl>;
  selectedHotelTypes?: InputMaybe<Array<InputMaybe<InputSelectedHotelTypesQl>>>;
  selectedTransportGroup?: InputMaybe<InputSelectedTransportGroupQl>;
  serializedConstraint?: InputMaybe<Scalars['String']['input']>;
  starRating?: InputMaybe<Scalars['Int']['input']>;
  stars?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  subIngredients?: InputMaybe<Array<InputMaybe<IngredientQl>>>;
  suggestedBy?: InputMaybe<InputAvailibityOrderQl>;
  tagId?: InputMaybe<Scalars['Int']['input']>;
  tickets?: InputMaybe<Array<InputMaybe<SelectedTicketQl>>>;
  toIata?: InputMaybe<Scalars['String']['input']>;
  transportTypes?: InputMaybe<Array<InputMaybe<InputTransportTypeQl>>>;
  vehicleType?: InputMaybe<InputTransportVehicleTypeQl>;
  vehicles?: InputMaybe<Array<InputMaybe<InputTransportVehicleTypeQl>>>;
};

export type IngredientResponseQl = {
  addToBasketToken?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
};

export enum InputAvailibityOrderQl {
  /** Cheapest */
  Cheapest = 'CHEAPEST',
  /** None */
  None = 'NONE',
  /** Popular */
  Popular = 'POPULAR',
}

export enum InputBoardBasisQl {
  /** AllInclusive */
  AllInclusive = 'ALL_INCLUSIVE',
  /** Breakfast */
  Breakfast = 'BREAKFAST',
  /** FullBoard */
  FullBoard = 'FULL_BOARD',
  /** HalfBoard */
  HalfBoard = 'HALF_BOARD',
  /** Lunch */
  Lunch = 'LUNCH',
  /** RoomOnly */
  RoomOnly = 'ROOM_ONLY',
}

export enum InputDynamicDatePreferencesModeTypeQl {
  /** MonthsFromNow */
  MonthsFromNow = 'MONTHS_FROM_NOW',
  /** PreferredMonths */
  PreferredMonths = 'PREFERRED_MONTHS',
  /** Unknown */
  Unknown = 'UNKNOWN',
}

export enum InputIngredientTypeQl {
  /** Ferry */
  Ferry = 'FERRY',
  /** Flight */
  Flight = 'FLIGHT',
  /** Hotel */
  Hotel = 'HOTEL',
  /** None */
  None = 'NONE',
  /** OwnStay */
  OwnStay = 'OWN_STAY',
  /** Ticket */
  Ticket = 'TICKET',
  /** Transport */
  Transport = 'TRANSPORT',
}

export type InputPaxGroupQl = {
  childAges?: InputMaybe<Array<Scalars['Int']['input']>>;
  numberOfAdults: Scalars['Int']['input'];
  numberOfChildren?: InputMaybe<Scalars['Int']['input']>;
};

export enum InputSelectedHotelTypesQl {
  /** Apartment */
  Apartment = 'APARTMENT',
  /** Golf */
  Golf = 'GOLF',
  /** Hotel */
  Hotel = 'HOTEL',
  /** HouseOrCottage */
  HouseOrCottage = 'HOUSE_OR_COTTAGE',
  /** None */
  None = 'NONE',
  /** Ticket */
  Ticket = 'TICKET',
}

export type InputSelectedTransportGroupQl = {
  arrivalTime: Scalars['String']['input'];
  departureTime: Scalars['String']['input'];
  fromIata: Scalars['String']['input'];
  landingExtraDays: Scalars['Int']['input'];
  mainCarrier: Scalars['String']['input'];
  toIata: Scalars['String']['input'];
};

export enum InputSourceTypeQl {
  /** Lab */
  Lab = 'LAB',
  /** MarketPlace */
  MarketPlace = 'MARKET_PLACE',
  /** PartnersPackageAPI */
  PartnersPackageApi = 'PARTNERS_PACKAGE_API',
  /** Planner */
  Planner = 'PLANNER',
  /** Unknown */
  Unknown = 'UNKNOWN',
}

export enum InputTransportTypeQl {
  /** Ferry */
  Ferry = 'FERRY',
  /** Flight */
  Flight = 'FLIGHT',
  /** None */
  None = 'NONE',
}

export enum InputTransportVehicleTypeQl {
  /** Bicycle */
  Bicycle = 'BICYCLE',
  /** MiniBus4mHigh6mLong */
  MiniBus_4MHigh_6MLong = 'MINI_BUS_4_M_HIGH_6_M_LONG',
  /** MiniBus8mLong */
  MiniBus_8MLong = 'MINI_BUS_8_M_LONG',
  /** MotorBike */
  MotorBike = 'MOTOR_BIKE',
  /** MultiPurposeVehicle */
  MultiPurposeVehicle = 'MULTI_PURPOSE_VEHICLE',
  /** None */
  None = 'NONE',
  /** Vehicle4m70cmLong */
  Vehicle_4M_70CmLong = 'VEHICLE_4_M_70_CM_LONG',
  /** Vehicle6mLong */
  Vehicle_6MLong = 'VEHICLE_6_M_LONG',
}

export type IntRangeQl = {
  __typename?: 'IntRangeQL';
  from?: Maybe<Scalars['Int']['output']>;
  to?: Maybe<Scalars['Int']['output']>;
};

export type ItineraryResponseQl = {
  __typename?: 'ItineraryResponseQL';
  itineraryKey: Scalars['String']['output'];
  result?: Maybe<Array<Maybe<IngredientResponseQl>>>;
  searchedAt: Scalars['DateTime']['output'];
  totalPrice?: Maybe<MoneyQl>;
};

export type LegQl = {
  __typename?: 'LegQL';
  durationInMinutes: Scalars['Float']['output'];
  segments?: Maybe<Array<Maybe<SegmentQl>>>;
};

export type LengthOfStayQl = {
  __typename?: 'LengthOfStayQL';
  nightsHaveBeenSuggested: Scalars['Boolean']['output'];
  numberOfNights: Scalars['Int']['output'];
};

export type LuggageInformationQl = {
  __typename?: 'LuggageInformationQL';
  included: Scalars['Boolean']['output'];
  pieces?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<LuggageTypeResult>;
  unit?: Maybe<LuggageUnitTypeResult>;
  weight?: Maybe<Scalars['Decimal']['output']>;
};

export enum LuggageTypeResult {
  CabinBag = 'CabinBag',
  CheckedBag = 'CheckedBag',
  None = 'None',
}

export enum LuggageUnitTypeResult {
  Kilograms = 'Kilograms',
  KilogramsAndPieces = 'KilogramsAndPieces',
  None = 'None',
  Pieces = 'Pieces',
}

export type MetadataRequestQl = {
  generatedFromAI?: InputMaybe<Scalars['Boolean']['input']>;
  originUrl?: InputMaybe<Scalars['String']['input']>;
  sourceType?: InputMaybe<InputSourceTypeQl>;
};

export type MoneyQl = {
  __typename?: 'MoneyQL';
  amount?: Maybe<Scalars['Decimal']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  currencySymbol?: Maybe<Scalars['String']['output']>;
};

export type NoteResponseQl = {
  __typename?: 'NoteResponseQL';
  fromDate?: Maybe<Scalars['String']['output']>;
  severity?: Maybe<Scalars['Int']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  toDate?: Maybe<Scalars['String']['output']>;
};

export type OwnStayResultQl = IngredientResponseQl & {
  __typename?: 'OwnStayResultQL';
  addToBasketToken?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  numberOfNights?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<MoneyQl>;
  subIngredientResults?: Maybe<Array<Maybe<IngredientResponseQl>>>;
  totalPrice?: Maybe<MoneyQl>;
};

export type PaxResponseQl = {
  __typename?: 'PaxResponseQL';
  age?: Maybe<Scalars['Int']['output']>;
};

export type PriceQl = {
  __typename?: 'PriceQL';
  deltaPrice?: Maybe<MoneyQl>;
  totalPrice?: Maybe<MoneyQl>;
};

export type PriceRangeQl = {
  __typename?: 'PriceRangeQL';
  from?: Maybe<MoneyQl>;
  to?: Maybe<MoneyQl>;
};

export type Query = {
  __typename?: 'Query';
  bookingTokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  cheapestItinerary?: Maybe<ItineraryResponseQl>;
  cheapestItineraryWithStatus?: Maybe<GetCheapestItineraryResponseQl>;
  getSuggestedAvailability?: Maybe<GetSuggestedAvailabilityResponseQl>;
  getThreadAI?: Maybe<AiThreadResponseQl>;
  getTransportList?: Maybe<TransportListResponseQl>;
  ratesByDay?: Maybe<Array<Maybe<DayTicketRateQl>>>;
  transportMatrix?: Maybe<TransportMatrixResponseQl>;
  validateRecipe?: Maybe<Scalars['Boolean']['output']>;
};

export type QueryBookingTokensArgs = {
  request: BookingTokenRequestQl;
};

export type QueryCheapestItineraryArgs = {
  recipe: RecipeQl;
};

export type QueryCheapestItineraryWithStatusArgs = {
  recipe: RecipeQl;
};

export type QueryGetSuggestedAvailabilityArgs = {
  request: GetSuggestedAvailabilityRequestQl;
};

export type QueryGetThreadAiArgs = {
  request: ThreadAiRequestQl;
};

export type QueryGetTransportListArgs = {
  request: TransportListRequestQl;
};

export type QueryRatesByDayArgs = {
  request: TicketRequestQl;
  skipCache?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryTransportMatrixArgs = {
  recipe: RecipeQl;
};

export type QueryValidateRecipeArgs = {
  recipe: RecipeQl;
};

export type RecipeQl = {
  affiliateId?: InputMaybe<Scalars['String']['input']>;
  days?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  dynamicDatePreferences?: InputMaybe<DynamicDatePreferencesQl>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  endDateOnly?: InputMaybe<Scalars['DateOnly']['input']>;
  featureToggles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ingredients?: InputMaybe<Array<InputMaybe<IngredientQl>>>;
  market: Scalars['Uri']['input'];
  metadata?: InputMaybe<MetadataRequestQl>;
  months?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  pax?: InputMaybe<Array<InputMaybe<InputPaxGroupQl>>>;
  returnEmptyIngredientResults?: InputMaybe<Scalars['Boolean']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
  skipCache?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDateOnly?: InputMaybe<Scalars['DateOnly']['input']>;
  suggestedBy?: InputMaybe<InputAvailibityOrderQl>;
};

export type RoomStayResponseQl = {
  __typename?: 'RoomStayResponseQL';
  boardBasis?: Maybe<Scalars['String']['output']>;
  cancellationPolicies?: Maybe<Array<Maybe<CancellationPolicyResponseQl>>>;
  description: Scalars['String']['output'];
  images?: Maybe<Array<Maybe<ImageResponseQl>>>;
  notes?: Maybe<Array<Maybe<NoteResponseQl>>>;
  pax?: Maybe<Array<Maybe<PaxResponseQl>>>;
  price?: Maybe<MoneyQl>;
  roomCode: Scalars['String']['output'];
  roomTypeId?: Maybe<Scalars['Int']['output']>;
  taxesAndFees?: Maybe<Array<Maybe<TaxAndFeeResponseQl>>>;
};

export type SegmentQl = {
  __typename?: 'SegmentQL';
  arrivalDateTime: Scalars['DateTime']['output'];
  arrivalIata: Scalars['String']['output'];
  carrier: Scalars['String']['output'];
  departureDateTime: Scalars['DateTime']['output'];
  departureIata: Scalars['String']['output'];
  durationInMinutes: Scalars['Float']['output'];
  flightNumber: Scalars['String']['output'];
  isCabinMandatory: Scalars['Boolean']['output'];
  layoverTimeUntilNextSegmentInMinutes?: Maybe<Scalars['Float']['output']>;
  luggageInformation?: Maybe<Array<Maybe<LuggageInformationQl>>>;
  operatingCarrier: Scalars['String']['output'];
  transportType?: Maybe<TransportType>;
  transportationName?: Maybe<Scalars['String']['output']>;
  vehicleType?: Maybe<TransportVehicleType>;
  vehicles?: Maybe<Array<Maybe<TransportVehicleType>>>;
};

export type SelectedTicketQl = {
  ageRangeId: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
  rateId: Scalars['String']['input'];
  timeSlotId?: InputMaybe<Scalars['String']['input']>;
};

export type StringRangeTypeQl = {
  from?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionsAsInRealtimeNotifications = {
  __typename?: 'SubscriptionsAsInRealtimeNotifications';
  getCheapestStayByHotel?: Maybe<CheapestPriceQl>;
  searchCheapestStay?: Maybe<HotelStayResponseQl>;
  searchRecipeByItinerary?: Maybe<ItineraryResponseQl>;
};

export type SubscriptionsAsInRealtimeNotificationsGetCheapestStayByHotelArgs = {
  request: CheapestStayByHotelRequestQl;
};

export type SubscriptionsAsInRealtimeNotificationsSearchCheapestStayArgs = {
  request: CheapestHotelStayRequestQl;
};

export type SubscriptionsAsInRealtimeNotificationsSearchRecipeByItineraryArgs =
  {
    recipe: RecipeQl;
  };

export type SuggestedAvailabilityResultQl = {
  __typename?: 'SuggestedAvailabilityResultQL';
  code: Scalars['String']['output'];
  score: Scalars['Int']['output'];
  totalPrice: MoneyQl;
};

export type TaxAndFeeResponseQl = {
  __typename?: 'TaxAndFeeResponseQL';
  amount?: Maybe<MoneyQl>;
  isIncludedInPrice: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
};

export type ThreadAiRequestQl = {
  market?: InputMaybe<Scalars['Uri']['input']>;
  messageId?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
  threadId?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

export type TicketIngredientResponseQl = IngredientResponseQl & {
  __typename?: 'TicketIngredientResponseQL';
  addToBasketToken?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  price?: Maybe<MoneyQl>;
  totalPrice?: Maybe<MoneyQl>;
};

export type TicketRateQl = {
  __typename?: 'TicketRateQL';
  ageRanges?: Maybe<Array<Maybe<AgeRangeQl>>>;
  maximumAmountTickets?: Maybe<Scalars['Int']['output']>;
  minimumAmountTickets?: Maybe<Scalars['Int']['output']>;
  rateId: Scalars['String']['output'];
  requiredAgeRanges?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  timeSlots?: Maybe<Array<Maybe<TimeSlotsQl>>>;
  title: Scalars['String']['output'];
};

export type TicketRequestQl = {
  affiliateId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  fromDate?: InputMaybe<Scalars['DateTime']['input']>;
  fromDateOnly?: InputMaybe<Scalars['DateOnly']['input']>;
  market: Scalars['Uri']['input'];
  pax?: InputMaybe<Array<InputPaxGroupQl>>;
  toDate?: InputMaybe<Scalars['DateTime']['input']>;
  toDateOnly?: InputMaybe<Scalars['DateOnly']['input']>;
};

export type TimeSlotsQl = {
  __typename?: 'TimeSlotsQL';
  id: Scalars['String']['output'];
  startTime: Scalars['DateTime']['output'];
};

export type TransportGroupValueQl = {
  __typename?: 'TransportGroupValueQL';
  carriers: Array<Maybe<Scalars['String']['output']>>;
  combinableWith: Array<Scalars['Int']['output']>;
  departureTime: Scalars['String']['output'];
  durationInMinutes?: Maybe<DoubleRangeQl>;
  hasRestriction: Scalars['Boolean']['output'];
  iataCodes: Array<Maybe<Scalars['String']['output']>>;
  id: Scalars['Int']['output'];
  isHomeboundRoundtrip: Scalars['Boolean']['output'];
  isOutboundRoundtrip: Scalars['Boolean']['output'];
  isSelectable?: Maybe<Scalars['Boolean']['output']>;
  isSelected: Scalars['Boolean']['output'];
  landingExtraDays: Scalars['Int']['output'];
  landingTime: Scalars['String']['output'];
  price?: Maybe<PriceRangeQl>;
  restrictionWithGroupInIngredient: Scalars['Int']['output'];
  roundtripTiedToIngredient?: Maybe<Scalars['Int']['output']>;
  stops?: Maybe<IntRangeQl>;
  transportsInGroup: Scalars['Int']['output'];
};

export type TransportIngredientResponseQl = IngredientResponseQl & {
  __typename?: 'TransportIngredientResponseQL';
  addToBasketToken?: Maybe<Scalars['String']['output']>;
  allCarriers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  alternativeSuggestion?: Maybe<TransportResultQl>;
  date?: Maybe<Scalars['DateTime']['output']>;
  durationRangeInMinutes?: Maybe<DoubleRangeQl>;
  key?: Maybe<Scalars['String']['output']>;
  legIndex?: Maybe<Scalars['Int']['output']>;
  legs?: Maybe<Array<Maybe<LegQl>>>;
  price?: Maybe<MoneyQl>;
  suggested?: Maybe<TransportResultQl>;
  totalPrice?: Maybe<MoneyQl>;
};

export type TransportListRequestQl = {
  dayOnly?: InputMaybe<Scalars['DateOnly']['input']>;
  elementsInPage: Scalars['Int']['input'];
  ingredientIndex: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  recipe?: InputMaybe<RecipeQl>;
};

export type TransportListResponseQl = {
  __typename?: 'TransportListResponseQL';
  totalTransports: Scalars['Int']['output'];
  transports?: Maybe<Array<Maybe<TransportResultQl>>>;
};

export type TransportMatrixResponseQl = {
  __typename?: 'TransportMatrixResponseQL';
  response?: Maybe<Array<Maybe<Array<Maybe<TransportGroupValueQl>>>>>;
};

export type TransportResultQl = {
  __typename?: 'TransportResultQL';
  addToBasketToken: Scalars['String']['output'];
  legIndex: Scalars['Int']['output'];
  legs?: Maybe<Array<Maybe<LegQl>>>;
  price?: Maybe<MoneyQl>;
};

export enum TransportType {
  Ferry = 'Ferry',
  Flight = 'Flight',
  None = 'None',
}

export enum TransportVehicleType {
  Bicycle = 'Bicycle',
  MiniBus4mHigh6mLong = 'MiniBus4mHigh6mLong',
  MiniBus8mLong = 'MiniBus8mLong',
  MotorBike = 'MotorBike',
  MultiPurposeVehicle = 'MultiPurposeVehicle',
  None = 'None',
  Vehicle4m70cmLong = 'Vehicle4m70cmLong',
  Vehicle6mLong = 'Vehicle6mLong',
}

export type MoneyFragment = {
  __typename?: 'MoneyQL';
  amount?: any | null;
  currency?: string | null;
  currencySymbol?: string | null;
};

export type TicketFragment = {
  __typename?: 'TicketIngredientResponseQL';
  code?: string | null;
};

export type GetCheapestItineraryWithStatusQueryVariables = Exact<{
  recipe: RecipeQl;
}>;

export type GetCheapestItineraryWithStatusQuery = {
  __typename?: 'Query';
  cheapestItineraryWithStatus?: {
    __typename?: 'GetCheapestItineraryResponseQL';
    status: CheapestItineraryStatusType;
    failureReason?: string | null;
    itinerary?: {
      __typename: 'ItineraryResponseQL';
      itineraryKey: string;
      totalPrice?: {
        __typename: 'MoneyQL';
        amount?: any | null;
        currency?: string | null;
        currencySymbol?: string | null;
      } | null;
      result?: Array<
        | { __typename: 'FerryIngredientResponseQL' }
        | { __typename: 'FlightIngredientResponseQL'; date?: Date | null }
        | {
            __typename: 'HotelIngredientResponseQL';
            lengthOfStay?: {
              __typename?: 'LengthOfStayQL';
              numberOfNights: number;
            } | null;
            suggested?: {
              __typename: 'HotelStayResponseQL';
              hotelCode: string;
            } | null;
            subIngredientResults?: Array<
              | { __typename: 'FerryIngredientResponseQL' }
              | { __typename: 'FlightIngredientResponseQL' }
              | { __typename: 'HotelIngredientResponseQL' }
              | { __typename: 'OwnStayResultQL' }
              | {
                  __typename: 'TicketIngredientResponseQL';
                  code?: string | null;
                }
              | { __typename: 'TransportIngredientResponseQL' }
              | null
            > | null;
          }
        | { __typename: 'OwnStayResultQL' }
        | { __typename: 'TicketIngredientResponseQL' }
        | { __typename: 'TransportIngredientResponseQL' }
        | null
      > | null;
    } | null;
  } | null;
};

export type GetThreadAiQueryVariables = Exact<{
  request: ThreadAiRequestQl;
}>;

export type GetThreadAiQuery = {
  __typename?: 'Query';
  getThreadAI?: {
    __typename?: 'AIThreadResponseQL';
    recipeJson?: string | null;
    threadId?: string | null;
    messageId?: string | null;
    sessionId?: string | null;
    replyToUser?: string | null;
    additionalTexts?: {
      __typename?: 'AdditionalTextQL';
      inspirationalMessage?: string | null;
      id?: string | null;
      destinations?: Array<{
        __typename?: 'DestinationAdditionalTextQL';
        name?: string | null;
        activityTexts?: Array<string | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type ValidateRecipeQueryVariables = Exact<{
  recipe: RecipeQl;
}>;

export type ValidateRecipeQuery = {
  __typename?: 'Query';
  validateRecipe?: boolean | null;
};

export const MoneyFragmentDoc = gql`
  fragment Money on MoneyQL {
    amount
    currency
    currencySymbol
  }
`;
export const TicketFragmentDoc = gql`
  fragment Ticket on TicketIngredientResponseQL {
    code
  }
`;
export const GetCheapestItineraryWithStatusDocument = gql`
  query GetCheapestItineraryWithStatus($recipe: RecipeQL!) {
    cheapestItineraryWithStatus(recipe: $recipe) {
      status
      failureReason
      itinerary {
        itineraryKey
        totalPrice {
          amount
          ...Money
          __typename
        }
        result {
          ... on HotelIngredientResponseQL {
            lengthOfStay {
              numberOfNights
            }
            suggested {
              hotelCode
              __typename
            }
            subIngredientResults {
              ...Ticket
              __typename
            }
            __typename
          }
          ... on FlightIngredientResponseQL {
            date
            __typename
          }
          __typename
        }
        __typename
      }
    }
  }
  ${MoneyFragmentDoc}
  ${TicketFragmentDoc}
`;

/**
 * __useGetCheapestItineraryWithStatusQuery__
 *
 * To run a query within a React component, call `useGetCheapestItineraryWithStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheapestItineraryWithStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheapestItineraryWithStatusQuery({
 *   variables: {
 *      recipe: // value for 'recipe'
 *   },
 * });
 */
export function useGetCheapestItineraryWithStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCheapestItineraryWithStatusQuery,
    GetCheapestItineraryWithStatusQueryVariables
  > &
    (
      | {
          variables: GetCheapestItineraryWithStatusQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCheapestItineraryWithStatusQuery,
    GetCheapestItineraryWithStatusQueryVariables
  >(GetCheapestItineraryWithStatusDocument, options);
}
export function useGetCheapestItineraryWithStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCheapestItineraryWithStatusQuery,
    GetCheapestItineraryWithStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCheapestItineraryWithStatusQuery,
    GetCheapestItineraryWithStatusQueryVariables
  >(GetCheapestItineraryWithStatusDocument, options);
}
export function useGetCheapestItineraryWithStatusSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetCheapestItineraryWithStatusQuery,
    GetCheapestItineraryWithStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetCheapestItineraryWithStatusQuery,
    GetCheapestItineraryWithStatusQueryVariables
  >(GetCheapestItineraryWithStatusDocument, options);
}
export type GetCheapestItineraryWithStatusQueryHookResult = ReturnType<
  typeof useGetCheapestItineraryWithStatusQuery
>;
export type GetCheapestItineraryWithStatusLazyQueryHookResult = ReturnType<
  typeof useGetCheapestItineraryWithStatusLazyQuery
>;
export type GetCheapestItineraryWithStatusSuspenseQueryHookResult = ReturnType<
  typeof useGetCheapestItineraryWithStatusSuspenseQuery
>;
export type GetCheapestItineraryWithStatusQueryResult = Apollo.QueryResult<
  GetCheapestItineraryWithStatusQuery,
  GetCheapestItineraryWithStatusQueryVariables
>;
export const GetThreadAiDocument = gql`
  query getThreadAI($request: ThreadAIRequestQL!) {
    getThreadAI(request: $request) {
      recipeJson
      additionalTexts {
        inspirationalMessage
        destinations {
          name
          activityTexts
        }
        id
      }
      threadId
      messageId
      sessionId
      replyToUser
    }
  }
`;

/**
 * __useGetThreadAiQuery__
 *
 * To run a query within a React component, call `useGetThreadAiQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThreadAiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThreadAiQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGetThreadAiQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetThreadAiQuery,
    GetThreadAiQueryVariables
  > &
    (
      | { variables: GetThreadAiQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetThreadAiQuery, GetThreadAiQueryVariables>(
    GetThreadAiDocument,
    options
  );
}
export function useGetThreadAiLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetThreadAiQuery,
    GetThreadAiQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetThreadAiQuery, GetThreadAiQueryVariables>(
    GetThreadAiDocument,
    options
  );
}
export function useGetThreadAiSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetThreadAiQuery,
    GetThreadAiQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetThreadAiQuery, GetThreadAiQueryVariables>(
    GetThreadAiDocument,
    options
  );
}
export type GetThreadAiQueryHookResult = ReturnType<typeof useGetThreadAiQuery>;
export type GetThreadAiLazyQueryHookResult = ReturnType<
  typeof useGetThreadAiLazyQuery
>;
export type GetThreadAiSuspenseQueryHookResult = ReturnType<
  typeof useGetThreadAiSuspenseQuery
>;
export type GetThreadAiQueryResult = Apollo.QueryResult<
  GetThreadAiQuery,
  GetThreadAiQueryVariables
>;
export const ValidateRecipeDocument = gql`
  query validateRecipe($recipe: RecipeQL!) {
    validateRecipe(recipe: $recipe)
  }
`;

/**
 * __useValidateRecipeQuery__
 *
 * To run a query within a React component, call `useValidateRecipeQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateRecipeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateRecipeQuery({
 *   variables: {
 *      recipe: // value for 'recipe'
 *   },
 * });
 */
export function useValidateRecipeQuery(
  baseOptions: Apollo.QueryHookOptions<
    ValidateRecipeQuery,
    ValidateRecipeQueryVariables
  > &
    (
      | { variables: ValidateRecipeQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ValidateRecipeQuery, ValidateRecipeQueryVariables>(
    ValidateRecipeDocument,
    options
  );
}
export function useValidateRecipeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ValidateRecipeQuery,
    ValidateRecipeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ValidateRecipeQuery, ValidateRecipeQueryVariables>(
    ValidateRecipeDocument,
    options
  );
}
export function useValidateRecipeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ValidateRecipeQuery,
    ValidateRecipeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ValidateRecipeQuery,
    ValidateRecipeQueryVariables
  >(ValidateRecipeDocument, options);
}
export type ValidateRecipeQueryHookResult = ReturnType<
  typeof useValidateRecipeQuery
>;
export type ValidateRecipeLazyQueryHookResult = ReturnType<
  typeof useValidateRecipeLazyQuery
>;
export type ValidateRecipeSuspenseQueryHookResult = ReturnType<
  typeof useValidateRecipeSuspenseQuery
>;
export type ValidateRecipeQueryResult = Apollo.QueryResult<
  ValidateRecipeQuery,
  ValidateRecipeQueryVariables
>;
